<template>
    <div class="list-dicom">
		<div class="modal fade" id="dicomListModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="dicomListModalLabel">Lista de exames</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="dicom-filter py-2">
							<input type="text" class="form-control form-control-sm w-50" placeholder="Buscar..." v-model="dicom_filter">
						</div>
						<div class="dicom-container" v-for="dicom in filteredList" :key="dicom.id" style="border: 1px solid #ccc; border-radius: 3px;">
							<div class="row" style="padding: 0px 12px;">
								<div class="col-12" style="border-bottom: 1px solid #ccc;">
									<span>ID: {{dicom.id}}</span>
									<span class="float-end text-muted">{{dicom.study_date}}</span>
								</div>
								<div class="col-6 p-2" style="border-right: 1px solid #ccc;">
									<img class="img-fluid rounded" :src="dicom.url_thumb" alt="">
								</div>
								<div class="col-6">
									<div class="dicom-name">
										<span>Nome: </span>
										<span class="float-end">{{dicom.dicom_name}}</span>
									</div>
									<div class="dicom-instance">
										<span>Instancia: </span>
										<span class="float-end">{{dicom.instances}}</span>
									</div>
									<div class="dicom-modality">
										<span>Tipo: </span>
										<span class="float-end">{{dicom.modality}}</span>
									</div>
									<div class="dicom-description">
										<span>Descrição: </span>
										<span class="float-end">{{dicom.description}}</span>
									</div>
									<div class="dicom-id">
										<span>ID Dicom: </span>
										<span class="float-end">{{dicom.id_dicom}}</span>
									</div>
									<div class="dicom-parts">
										<span>Parte: </span>
										<span class="float-end">{{dicom.body_parts}}</span>
									</div>
									<div class="dicom-link text-center py-2">
										<a :href="dicom.url_exam" target="_blank" class="btn">Ver Exame</a>
										&nbsp;&nbsp;
										<button type="button" class="btn btn-send" @click="sendMessage(dicom.url_thumb,dicom.url_exam)">Enviar</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
import axios from "axios"
import { API, id_app, language_padrao } from '../utils/constants'
export default {
	props:{
		currentFarmaId:{type: String, required:true},
		roomId:{type: String, required:true}
	},
	data(){
		return {
			modal: null,
			loadingList: true,
			dicom_filter: "",
			dicomList: []		}
	},
	computed:{
		filteredList(){
			var list = this.dicomList
			var filteredList = list.filter(
				item => item.id.includes(this.dicom_filter.toLowerCase())
				|| item.dicom_name.toLowerCase().includes(this.dicom_filter.toLowerCase())
				|| item.instances.toLowerCase().includes(this.dicom_filter.toLowerCase())
				|| item.modality.toLowerCase().includes(this.dicom_filter.toLowerCase())
				|| item.description.toLowerCase().includes(this.dicom_filter.toLowerCase())
				|| item.id_dicom.toLowerCase().includes(this.dicom_filter.toLowerCase())
				|| item.body_parts.toLowerCase().includes(this.dicom_filter.toLowerCase())
			)
			return filteredList
		}
	},
	mounted(){
		const modal = document.querySelector("#dicomListModal")
		this.modal = new window.bootstrap.Modal(modal)

		modal.addEventListener("show.bs.modal", ()=>{
			console.log("show");
			this.getDicomList()
		})

		modal.addEventListener("hide.bs.modal", ()=>{
			console.log("hide");
			this.dicomList = []
			this.loadingList = true
		})
	},
	methods:{
		getDicomList(){
			console.log("get dicom list function");
			var self = this
            var settings = {
                "url": API+"obs/dicom/",
                "method": "GET",
                "headers": {
                "X-Auth-Token": localStorage.getItem("api_token"),
                "X-Id-Language": language_padrao,
                "X-Id-APP": id_app,
				"X-Farma": this.currentFarmaId,
                "X-Dicom": ''
                },
            };

            axios(settings).then(response=>{
				console.log(JSON.stringify(response.data.list));
				self.dicomList = response.data.list
				self.loadingList = false
            }).catch(function(erro) {
                console.log(erro);
            });
		},
		sendMessage(url_thumb,url_exam){
			var content = ''
			var roomId = this.roomId
			var files = null
			var replyMessage = null
			var type = 4
			var url = encodeURIComponent(url_thumb)
			var urlExam = encodeURIComponent(url_exam)
			var options = {url,urlExam}
			this.$emit("sendMessage", { content, roomId, files, replyMessage }, type, options)
			this.$emit('closeDicomModal')
		}
	}
}
</script>


<style scoped>
.dicom-list-description {
	height: 70px;
    overflow-y: auto;
    padding: 5px;
    margin: 10px 0;
}
.dicom-list-link a{
	background-color: var(--chat-notify-button-bg-color);
	color: var(--chat-notify-button-color);
}

.dicom-link{
	border-top: 1px solid #ccc
}

.dicom-link .btn, .modal-footer .btn-send{
	background-color: var(--chat-notify-button-bg-color);
	color: var(--chat-notify-button-color);
}
</style>

<template>
    <div class="login-page login-form-celular">
        <!--
        <v-card class="px-5 py-5 mx-auto" max-width="500px" height="320px">
            <v-form class="px-5" ref="form" v-model="loginCelularComponent.formCelularValid">
                <v-text-field v-model="loginCelularComponent.celular" :rules="loginCelularComponent.rules.celularRules" label="Celular" outlined>
                </v-text-field>
                <v-spacer></v-spacer>
                <div class="btn-login mt-2 text-center">
                    <v-btn id="btnLoginCelular" block color="primary" @click="loginCelular"> Entrar </v-btn>
                </div>
                <div class="btn-login mt-2 text-center">
                    <v-btn block color="error" @click="$router.back()"> Voltar </v-btn>
                </div>
            </v-form>
        </v-card>
        -->
        <div class="modal-login-celular">
            <form @submit.prevent="loginCelular">
                <div class="mb-3">
                    <label for="txtemail" class="form-label">Telefone</label>
                    <input type="text" class="form-control form-control-sm" id="txtTelefone" name="txtTelefone" v-model="loginCelularComponent.celular" required>
                </div>
				<button type="button" class="btn-voltar btn btn-sm" @click="$router.push('/login')">Voltar</button>
                <button type="submit" class="btn-login btn btn-sm float-end" id="btnLoginCelular">Entrar</button>
            </form>
        </div>
    </div>
</template>

<script>
import {
    getAuth,
    RecaptchaVerifier,
    signInWithPhoneNumber,
} from "firebase/auth";
export default {
    data(){
        return{
            loginCelularComponent:{
                celular: ''
            }
        }
    },
    methods:{
        loginCelular(){
            var _this = this
            const auth = getAuth();
            var captcha = new RecaptchaVerifier('btnLoginCelular', {
                        'size': 'invisible',
                    }, auth);
                    var numero = '+5511923456789'
            console.log(this.loginCelularComponent.celular);
                signInWithPhoneNumber(auth, numero, captcha)
                .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                    var code = prompt("Qual o codigo?");
                    confirmationResult.confirm(code).then((result) => {
                    // User signed in successfully.
                    const user = result.user;
                    console.log(user);
                    _this.fcLogin(user).then(function(response) {
                            console.log(response);
                            localStorage.setItem("api_token", response.data.list.api_token);
                            localStorage.setItem("api_token_reset", response.data.list.api_token_reset);
                            if (user.email == null || user.displayName == null) {
                                let email = user.email==null?'':user.email
                                let nome = user.displayName==null?'':user.displayName
                                _this.$router.push({name:'registro', params:{tipo:'celular', email: email, nome: nome}})
                            }else{
                                _this.fcUserComplete('oauth', user);
                            }
                        }).catch(function(erro) {
                            console.log(erro);
                        });


                    // ...
                    }).catch((error) => {
                        console.log(error);
                    // User couldn't sign in (bad verification code?)
                    // ...
                    });
                // ...
                }).catch((error) => {
                    console.log(error);
                // Error; SMS not sent
                // ...
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.login-form-celular{
    padding-top: 50px;
	font-size: .9em;
}
.modal-login-celular{
    max-width: 500px;
    box-shadow: 3px 3px 8px 0px #ccc;
    margin: auto;
    padding: 10px;
}
.form-control{
	font-size: .9em;
}
.btn-login{
	color: #fff;
	background-color: #5aa5ef;
}
.btn-voltar{
	color: #fff;
	background-color: #e57171;
}
</style>

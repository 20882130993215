<template>
	<div class="vac-card-window" :style="[{ height }]">
		<div class="vac-chat-container">
			<rooms-list
				v-if="!singleRoom"
				:loading-messages="loadingMessages"
				:current-user-id="currentUserId"
				:ordem="ordem"
				:tagFilter="tagFilter"
				:rooms="orderedRooms"
				:loading-rooms="loadingRooms"
				:rooms-loaded="roomsLoaded"
				:room="room"
				:room-actions="roomActions"
				:text-messages="t"
				:show-search="showSearch"
				:show-add-room="showAddRoom"
				:show-rooms-list="showRoomsList"
				:text-formatting="textFormatting"
				:link-options="linkOptions"
				:is-mobile="isMobile"
				@clearFilterTag="clearFilterTag"
				@change-order="changeOrder"
				@fetch-room="fetchRoom"
				@fetch-more-rooms="fetchMoreRooms"
				@loading-more-rooms="loadingMoreRooms = $event"
				@add-room="addRoom"
				@room-action-handler="roomActionHandler"
			>
				<template v-for="(i, name) in $scopedSlots" #[name]="data">
					<slot :name="name" v-bind="data" />
				</template>
			</rooms-list>

			<room
				:current-user-id="currentUserId"
				:rooms="rooms"
				:tagFilter="tagFilter"
				:room-id="room.id_room || roomId"
				:load-first-room="loadFirstRoom"
				:messages="messages"
				:room-message="roomMessage"
				:messages-loaded="messagesLoaded"
				:messages-loaded-bottom="messagesLoadedBottom"
				:menu-actions="menuActions"
				:message-actions="messageActions"
				:show-send-icon="showSendIcon"
				:show-files="showFiles"
				:show-audio="showAudio"
				:audio-bit-rate="audioBitRate"
				:audio-sample-rate="audioSampleRate"
				:show-emojis="showEmojis"
				:show-reaction-emojis="showReactionEmojis"
				:show-new-messages-divider="showNewMessagesDivider"
				:show-footer="showFooter"
				:text-messages="t"
				:single-room="singleRoom"
				:show-rooms-list="showRoomsList"
				:text-formatting="textFormatting"
				:link-options="linkOptions"
				:is-mobile="isMobile"
				:loading-rooms="loadingRooms"
				:room-info-enabled="roomInfoEnabled"
				:textarea-action-enabled="textareaActionEnabled"
				:accepted-files="acceptedFiles"
				:templates-text="templatesText"
				@clearFilterTag="clearFilterTag"
				@filterChatTag="filterChatTag"
				@openDicomModal="openDicomModal"
				@openCaseModal="openCaseModal"
				@toggle-rooms-list="toggleRoomsList"
				@room-info="roomInfo"
				@fetch-messages="fetchMessages"
				@send-message="sendMessage"
				@edit-message="editMessage"
				@delete-message-to-me="deleteMessageToMe"
				@delete-message-to-all="deleteMessageToAll"
				@open-file="openFile"
				@open-user-tag="openUserTag"
				@menu-action-handler="menuActionHandler"
				@message-action-handler="messageActionHandler"
				@send-message-reaction="sendMessageReaction"
				@typing-message="typingMessage"
				@textarea-action-handler="textareaActionHandler"
			>
				<template v-for="(i, name) in $scopedSlots" #[name]="data">
					<slot :name="name" v-bind="data" />
				</template>
			</room>
		</div>
	</div>
</template>

<script>
import RoomsList from './RoomsList/RoomsList'
import Room from './Room/Room'

import locales from '../locales'
/*
const {
	roomsValidation,
	partcipantsValidation
} = require('../utils/data-validation')
*/
export default {
	name: 'ChatContainer',
	components: {
		RoomsList,
		Room
	},

	props: {
		loadingMessages: { type: Boolean, required: true },
		height: { type: String, default: '600px' },
		theme: { type: String, default: 'pfizer' },
		styles: { type: Object, default: () => ({}) },
		responsiveBreakpoint: { type: Number, default: 900 },
		singleRoom: { type: Boolean, default: false },
		roomsListOpened: { type: Boolean, default: true },
		textMessages: { type: Object, default: null },
		currentUserId: { type: [String, Number], default: '' },
		ordem: { type: String, required: true },
		tagFilter: { type: Object, required: true },
		rooms: { type: Array, default: () => [] },
		roomsOrder: { type: String, default: 'desc' },
		loadingRooms: { type: Boolean, default: false },
		roomsLoaded: { type: Boolean, default: false },
		roomId: { type: String, default: null },
		loadFirstRoom: { type: Boolean, default: true },
		messages: { type: Array, default: () => [] },
		messagesLoaded: { type: Boolean, default: false },
		messagesLoadedBottom: { type: Boolean, default: false },
		roomActions: { type: Array, default: () => [] },
		menuActions: { type: Array, default: () => [] },
		messageActions: {
			type: Array,
			default: () => [
				{ name: 'replyMessage', title: 'Responder' },
				/*{ name: 'editMessage', title: 'Editar mensagem', onlyMe: true },*/
				{ name: 'deleteMessageToMe', title: 'Deletar mensagem apenas para mim'},
				{ name: 'deleteMessageToAll', title: 'Deletar mensagem para todos', onlyMe: true }
			]
		},
		showSearch: { type: Boolean, default: true },
		showAddRoom: { type: Boolean, default: false },
		showSendIcon: { type: Boolean, default: true },
		showFiles: { type: Boolean, default: true },
		showAudio: { type: Boolean, default: true },
		audioBitRate: { type: Number, default: 128 },
		audioSampleRate: { type: Number, default: 44100 },
		showEmojis: { type: Boolean, default: true },
		showReactionEmojis: { type: Boolean, default: true },
		showNewMessagesDivider: { type: Boolean, default: true },
		showFooter: { type: Boolean, default: true },
		textFormatting: { type: Boolean, default: true },
		linkOptions: {
			type: Object,
			default: () => ({ disabled: false, target: '_blank', rel: null })
		},
		roomInfoEnabled: { type: Boolean, default: false },
		textareaActionEnabled: { type: Boolean, default: false },
		roomMessage: { type: String, default: '' },
		acceptedFiles: { type: String, default: '*' },
		templatesText: { type: Array, default: null }
	},

	emits: [
		'toggle-rooms-list',
		'room-info',
		'fetch-messages',
		'send-message',
		'edit-message',
		'delete-message-to-me',
		'delete-message-to-all',
		'open-file',
		'open-user-tag',
		'menu-action-handler',
		'message-action-handler',
		'send-message-reaction',
		'typing-message',
		'textarea-action-handler',
		'fetch-more-rooms',
		'add-room',
		'room-action-handler'
	],

	data() {
		return {
			room: {},
			loadingMoreRooms: false,
			showRoomsList: true,
			isMobile: false
		}
	},
	computed: {
		t() {
			return {
				...locales,
				...this.textMessages
			}
		},
		orderedRooms() {
			return this.rooms
		}
	},

	watch: {
		rooms: {
			immediate: true,
			deep: true,
			handler(newVal, oldVal) {
				if (
					!newVal[0] ||
					!newVal.find(room => room.id_room === this.room.id_room)
				) {
					this.showRoomsList = true
				}

				if (
					!this.loadingMoreRooms &&
					this.loadFirstRoom &&
					newVal[0] &&
					(!oldVal || newVal.length !== oldVal.length)
				) {
					if (this.roomId) {
						const room = newVal.find(r => r.id_room === this.id_room) || {}
						this.fetchRoom({ room })
					} else if (!this.isMobile || this.singleRoom) {
						this.fetchRoom({ room: this.orderedRooms[0] })
					} else {
						this.showRoomsList = true
					}
				}
			}
		},

		loadingRooms(val) {
			if (val) this.room = {}
		},

		roomId: {
			immediate: true,
			handler(newVal, oldVal) {
				if (newVal && !this.loadingRooms && this.rooms.length) {
					const room = this.rooms.find(r => r.id_room == newVal)
					this.fetchRoom({ room })
				} else if (oldVal && !newVal) {
					this.room = {}
				}

			}
		},
		/*
		room(val) {
			if (!val || Object.entries(val).length === 0) return

			roomsValidation(val)

			val.users.forEach(user => {
				partcipantsValidation(user)
			})
		},
		*/
		roomsListOpened(val) {
			this.showRoomsList = val
		}
	},

	created() {
		this.updateResponsive()
		window.addEventListener('resize', ev => {
			if (ev.isTrusted) this.updateResponsive()
		})
	},

	methods: {
		changeOrder(order){
			this.$emit('change-order', order)
		},
		openDicomModal(){
			this.$emit("openDicomModal")
		},
		openCaseModal(){
			this.$emit("openCaseModal")
		},
		updateResponsive() {
			this.isMobile = window.innerWidth < this.responsiveBreakpoint
		},
		toggleRoomsList() {
			if (this.loadingMessages) return
			this.showRoomsList = !this.showRoomsList
			if (this.isMobile) this.room = {}
			this.$emit('toggle-rooms-list', { opened: this.showRoomsList })
			this.$eventBus.$emit('toggleDeviceComponent')
		},
		fetchRoom({ room }) {
			this.room = room
			this.fetchMessages({ reset: true, direction: 'last', xId: 0 })
			if (this.isMobile) this.showRoomsList = false
		},
		fetchMoreRooms() {
			this.$emit('fetch-more-rooms')
		},
		roomInfo() {
			this.$emit('room-info', this.room)
		},
		addRoom() {
			this.$emit('add-room')
		},
		fetchMessages(options) {
			this.$emit('fetch-messages', { room: this.room, options })
		},
		sendMessage(message) {
			this.$emit('send-message', { ...message, roomId: this.room.id_room })
		},
		editMessage(message) {
			this.$emit('edit-message', { ...message, roomId: this.room.roomId })
		},
		deleteMessageToMe(message) {
			this.$emit('delete-message-to-me', { message, roomId: this.room.id_room })
		},
		deleteMessageToAll(message) {
			this.$emit('delete-message-to-all', { message, roomId: this.room.id_room })
		},
		openFile({ message, file }) {
			this.$emit('open-file', { message, file })
		},
		openUserTag({ user }) {
			this.$emit('open-user-tag', { user })
		},
		menuActionHandler(ev) {
			this.$emit('menu-action-handler', {
				action: ev,
				roomId: this.room.id_room
			})
		},
		roomActionHandler({ action, roomId }) {
			this.$emit('room-action-handler', {
				action,
				roomId
			})
		},
		messageActionHandler(ev) {
			this.$emit('message-action-handler', {
				...ev,
				roomId: this.room.id_room
			})
		},
		sendMessageReaction(messageReaction) {
			this.$emit('send-message-reaction', {
				...messageReaction,
				roomId: this.room.id_room
			})
		},
		typingMessage(message) {
			this.$emit('typing-message', {
				message,
				roomId: this.room.id_room
			})
		},
		textareaActionHandler(message) {
			this.$emit('textarea-action-handler', {
				message,
				roomId: this.room.id_room
			})
		},
		clearFilterTag(tag){
			this.$emit('clearFilterTag', tag)
		},
		filterChatTag(tag){
			this.$emit('filterChatTag', tag)
		},
	}
}
</script>

<style lang="scss">
@import '../styles/index.scss';
</style>

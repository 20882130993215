<template>
	<div class="email-verificacao">
		<div class="modal-email-verificacao text-center">
			<h1>Email não verificado</h1>
			<p>Um email de verificação foi enviado para o email {{$route.params.email}}</p>
			<small>Atualize essa tela após a confirmação do cadastro ou acesse o sistema via convite para acessar a plataforma</small>
			<br><br>
			<small>Caso não tenha recebido clique no botão abaixo para reenviar o email</small>
			<br><br>
			<Button class="btn btn-success btn-sm" @click="fcSendEmail">Enviar email</Button>
		</div>
	</div>
</template>

<script>
import {
    getAuth,
    sendEmailVerification,
	onAuthStateChanged
} from "firebase/auth";
export default {
	created(){
		let self = this;
		if (!this.$route.params.email) {
			this.$router.push('/login')
		}else{
			self.getStatusChange()
		}
	},
	methods:{
		fcSendEmail(){
            const auth = getAuth()
            var user = auth.currentUser;

			let userInvite = localStorage.getItem('invite') ? localStorage.getItem('invite') : '';
			let urlEmailVerificacao = `${location.origin}/${userInvite}`
            sendEmailVerification(user,{url:urlEmailVerificacao})
			.then(function() {
				alert('email enviado')
			})
			.catch(function(erro) {
				console.log(erro);
				// Error occurred. Inspect error.code.
			});
		},
		getStatusChange(){
			const auth = getAuth()
			onAuthStateChanged(auth, (user) => {
				if (user) {
					if(user.emailVerified){
						this.$router.push('/')
					}
				} else {
					this.$router.push('/login')
				}
			});
		}

	}
}
</script>

<style lang="scss" scoped>
.email-verificacao{
    padding-top: 50px;
}
.modal-email-verificacao{
    max-width: 500px;
    box-shadow: 3px 3px 8px 0px #ccc;
    margin: auto;
    padding: 10px;
	background-color: #fafafa;

}
</style>

<template>
    <div class="login-page login-options">
        <div class="modal-opt">
			<div class="img-logo text-center">
				<img src="../assets/allm_logo.svg" alt="">
			</div>
			<div class="text-center login-opt login-google">
                <button class="btn btn-light" @click="loginGoogle"> Login com Google </button>
            </div>
            <div class="text-center login-opt login-email my-3" @click="$router.push('/login-email')">
                <button class="btn btn-light"> Login com Email </button>
            </div>
			<!--
			<div class="text-center login-opt login-celular my-3" @click="$router.push('/login-celular')">
                <button class="btn btn-light"> Login com Celular </button>
            </div>
            <div class="text-center login-opt login-facebook my-3">
                <button class="btn btn-light" @click="loginFacebook"> Login com Facebook </button>
            </div>

            <div class="text-center login-opt login-github my-3">
                <button class="btn btn-light"> Login com Github </button>
            </div>
            <div class="text-center login-opt login-apple my-3 d-none">
                <button class="btn btn-light"> Login com Apple </button>
            </div>

            <div class="text-center login-opt login-microsoft my-3 d-none">
                <button class="btn btn-light" @click="loginMicrosoft"> Login com Microsoft </button>
            </div>

            <div class="text-center login-opt login-twitter my-3">
                <button class="btn btn-light" @click="loginTwitter"> Login com Twitter </button>
            </div>
            <div class="text-center login-opt login-yahoo my-3">
                <button class="btn btn-light" @click="loginYahoo"> Login com Yahoo </button>
            </div>
			-->
        </div>

    </div>
</template>
<script>
import {
    getAuth,
    signInWithPopup,
    GoogleAuthProvider,
    FacebookAuthProvider,
    GithubAuthProvider,
    TwitterAuthProvider,
    OAuthProvider
} from "firebase/auth";
export default {
    methods:{
        loginGoogle(){
            var self = this
            const provider = new GoogleAuthProvider();
            const auth = getAuth();
            signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                // The signed-in user info.
                const user = result.user;
                self.fcLogin(user).then(function(response) {
                    console.log(response);
                    localStorage.setItem("api_token", response.data.list.api_token);
                    localStorage.setItem("api_token_reset", response.data.list.api_token_reset);
                    self.fcUserComplete('oauth', user);
                }).catch(function(erro) {
                    console.log(erro);
                });
            }).catch((error) => {
                // Handle Errors here.
                console.log(error);
                // ...
            });
        },
        loginFacebook(){
            var self = this
            const provider = new FacebookAuthProvider();
            const auth = getAuth();
            signInWithPopup(auth, provider)
            .then((result) => {
                // The signed-in user info.
                const user = result.user;

                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                const credential = FacebookAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;
                console.log(accessToken);
                console.log(user);
                self.fcLogin(user).then(function(response) {
                    localStorage.setItem("api_token", response.data.list.api_token);
                    localStorage.setItem("api_token_reset", response.data.list.api_token_reset);
                    self.fcUserComplete('oauth', user);
                }).catch(function(erro) {
                    console.log(erro);
                });
            })
            .catch((error) => {
                // Handle Errors here.
                console.log(error);

                // ...
            });
        },
        loginGithub(){
            var self = this
            const provider = new GithubAuthProvider();
            const auth = getAuth();
            signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a GitHub Access Token. You can use it to access the GitHub API.
                const credential = GithubAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;

                // The signed-in user info.
                const user = result.user;
                console.log(token);
                console.log(user);
                self.fcLogin(user).then(function(response) {
                    localStorage.setItem("api_token", response.data.list.api_token);
                    localStorage.setItem("api_token_reset", response.data.list.api_token_reset);
                    self.fcUserComplete('oauth', user);
                }).catch(function(erro) {
                    console.log(erro);
                });
                // ...
            }).catch((error) => {
                // Handle Errors here.
                console.log(error);
                // ...
            });
        },
        loginTwitter(){
            var self = this
            const provider = new TwitterAuthProvider();
            const auth = getAuth();
            signInWithPopup(auth, provider).then(function(result) {
                // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
                // You can use these server side with your app's credentials to access the Twitter API.

                console.log(result);
                var user = result.user
                self.fcLogin(user).then(function(response) {
                    localStorage.setItem("api_token", response.data.list.api_token);
                    localStorage.setItem("api_token_reset", response.data.list.api_token_reset);
                    self.fcUserComplete('oauth', user);
                }).catch(function(erro) {
                    console.log(erro);
                });

            // ...
            }).catch(function(error) {
                // Handle Errors here.
                console.log(error);
            // ...
            });
        },
        loginYahoo(){
            var self = this
            const provider = new OAuthProvider('yahoo.com');
            const auth = getAuth();
            signInWithPopup(auth, provider)
            .then((result) => {
                // IdP data available in result.additionalUserInfo.profile
                // ...

                // Yahoo OAuth access token and ID token can be retrieved by calling:
                const credential = OAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;
                const idToken = credential.idToken;
                var user = result.user;
                console.log(accessToken);
                console.log(idToken);
                console.log(user);
                self.fcLogin(user).then(function(response) {
                    localStorage.setItem("api_token", response.data.list.api_token);
                    localStorage.setItem("api_token_reset", response.data.list.api_token_reset);
                    self.fcUserComplete('oauth', user);
                }).catch(function(erro) {
                    console.log(erro);
                });

            // ...
            }).catch(function(error) {
                // Handle Errors here.
                console.log(error);
            // ...
            });
        },
    }
}
</script>

<style lang="scss" scoped>

.img-logo{
	padding: 40px 0;
	img{
		max-width: 260px;
		width:100%
	}
}
.login-options{
    padding-top: 10px;
    margin: auto;
}
.login-opt.login-google .btn {
	background: #f2f2f2 url(../assets/google.png) no-repeat;
    background-position: 5px 7px;
	background-size: 35px;
}
.login-opt.login-facebook .btn {
	background: #1C407C url(../assets/facebook.png) no-repeat;
    background-position: 5px 7px;
	background-size: 35px;
    color: #fff;
}
.login-opt.login-github .btn {
	background: #333 url(../assets/github.png) no-repeat;
    background-position: 5px 7px;
	background-size: 35px;
    color: #fff;
}
.login-opt.login-apple .btn {
	background: #000 url(../assets/apple.png) no-repeat;
    background-position: 5px 7px;
	background-size: 35px;
    color: #fff;
}
.login-opt.login-microsoft .btn {
	background: #004fff url(../assets/microsoft.png) no-repeat;
    background-position: 5px 7px;
	background-size: 35px;
    color: #fff;
}
.login-opt.login-twitter .btn {
	background: #10a0e5 url(../assets/twitter.png) no-repeat;
    background-position: 5px 7px;
	background-size: 35px;
    color: #fff;
}
.login-opt.login-yahoo .btn {
	background: #7e26f1 url(../assets/yahoo.png) no-repeat;
    background-position: 5px 7px;
	background-size: 35px;
    color: #fff;
}
.login-opt.login-email .btn {
	background: #dc3545 url(../assets/email.png) no-repeat;
    background-position: 5px 7px;
	background-size: 35px;
	color: #fff;
}
.login-opt.login-celular .btn {
	background: #198754 url(../assets/phone.png) no-repeat;
    background-position: 5px 7px;
	background-size: 35px;
	color: #fff
}
.login-opt .btn{
    width: 260px;
    height: 50px;
	padding-left: 45px;
	font-weight: 600;

    &:hover{
        opacity: .8;
    }
}
/*
@media screen and (min-width: 577px) {
	.login-options{
		background: url(../assets/backLogin1.png);
		background-size: 100% 100%;
		background-repeat: no-repeat no-repeat;
		height: 100vh;
	}
}

@media screen and (max-width: 576px) {
	.login-options{
		background: inherit;
		height: 100vh;
	}
}
*/
</style>

<template>
	<div v-if="showContent" class="p-2">
		<div class="justify-content-center float-end">
			<button class="btn-sair btn btn-sm float-end" @click="logout">Sair</button>
			<span data-v-fae5bece="" class="filter-select user-logged float-end" style="line-height: 1;">
				{{user_profile.name}}
			</span>
		</div>
		<div
			class="app-container"
			id="chat-allm-container"
			:class="[{ 'app-mobile': isDevice, 'app-mobile-dark': theme === 'dark' }, getCss]"
			v-if="userFarma"
		>
			<div>
				<span v-if="showOptions" class="farma-logo">
					<img :src="showFarmaLogo" alt="">
				</span>
				<span
					v-if="showOptions"
					class="filter-select farmaceuticas"
				>
					Projeto
				</span>
				<select v-if="showOptions" v-model="currentFarmaId">
					<option v-for="farma in farmaceuticas" :key="farma.id_farma" :value="farma.id_farma">
					{{ farma.farma }}
					</option>
				</select>
				<notification-message :current-farma-id="currentFarmaId" :farmas="farmaceuticas" v-if="showChat"/>
				<chat-container

					v-if="showChat"
					:current-user-id="currentUserId"
					:current-farma-id="currentFarmaId"
					:ordem="ordem"
					:tagFilter="tagFilter"
					:theme="theme"
					:is-device="isDevice"
					@clearFilterTag="clearFilterTag"
					@change-order="changeOrder"
					@filter-tag="filterTag"
					@show-demo-options="showDemoOptions = $event"
				/>
			</div>
			<!-- <div class="version-container">
				v1.0.0
			</div> -->
		</div>
		<div class="no-farma" v-if="!userFarma">
			<div class="modal-no-farma">
				<p>Você não está inserido em nenhuma farmacêutica</p>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import {getAuth, onAuthStateChanged} from 'firebase/auth'
import ChatContainer from './ChatContainer'
import NotificationMessage from '../components/NotificationMessage/NotificationMessage.vue'
import { API, id_app, language_padrao } from '../utils/constants'

export default {
	components: {
		ChatContainer,
		NotificationMessage
	},

	data() {
		return {
			styles: () => ({}),
			theme: '',
			css:'',
			showChat: true,
			showContent: false,
			farmaceuticas: [],
			currentFarmaId: '',
			currentUserId: '',
			ordem:'chats',
			tagFilter: {},
			isDevice: false,
			showDemoOptions: true,
			user_profile:null,
			userFarma: null,
		}
	},

	computed: {
		getCss(){
			let farma = this.farmaceuticas.filter(item=>item.id_farma == this.currentFarmaId)
			return farma[0].css.replace('.css','')
		},
		showOptions() {
			return !this.isDevice || this.showDemoOptions
		},
		showFarmaLogo(){
			let farma = this.farmaceuticas.filter(item=>item.id_farma == this.currentFarmaId)
			let nomeFarma = farma[0].css.toLowerCase().replace('.css','')
			return require(`@/assets/logo_${nomeFarma}.png`)
		},
		getTheme(){
			let farma = this.farmaceuticas.filter(item=>item.id_farma == this.currentFarmaId)
			return farma[0].css.toLowerCase().replace('.css','')
		}
	},

	watch: {
		currentUserId() {
			this.showChat = false
			setTimeout(() => (this.showChat = true), 150)
		},
		currentFarmaId() {
			this.showChat = false
			this.theme = this.getTheme
			setTimeout(() => (this.showChat = true), 150)
		},
		ordem(newVal, oldVal) {
			if(oldVal != newVal){
				this.showChat = false
				setTimeout(() => (this.showChat = true), 150)
			}
		},
		tagFilter(newVal, oldVal){
			if(JSON.stringify(oldVal) != JSON.stringify(newVal)){
				this.showChat = false
				setTimeout(() => (this.showChat = true), 150)
			}
		}
	},

	mounted() {
		this.isDevice = window.innerWidth < 500
		window.addEventListener('resize', ev => {
			if (ev.isTrusted) this.isDevice = window.innerWidth < 500
		})

		//Get Farma
		this.getUserInfos()
	},

	methods: {
		changeOrder(order){
			this.ordem = order
		},
		filterTag(tag){
			this.tagFilter = tag
		},
		clearFilterTag(tag){
			this.filterTag(tag)
		},
		getUserInfos(){
			var self = this
			const auth = getAuth()
			var unsubscribe = onAuthStateChanged(auth, (user)=>{
				self.user_profile = {name:user.displayName,email: user.email,diaplayName: user.displayName,uid: user.uid}
				self.getFarmas()
			})
			unsubscribe()
		},
		getFarmas(){
            var self = this
            var settings = {
                "url": API+"farmas/",
                "method": "GET",
                "headers": {
                "X-Auth-Token": localStorage.getItem("api_token"),
                "X-Id-Language": language_padrao,
                "X-Id-APP": id_app,
                "X-Flg-Deleted": '0'
                },
            };

            axios(settings).then(response=>{
				self.showContent = true
				if (response.data.list.length == 0) {
					self.userFarma = false
					return
				}
                self.farmaceuticas = response.data.list
                self.currentFarmaId = response.data.list[0].id_farma
				self.loadCss()
				self.userFarma = true
            }).catch(function(erro) {
                console.log(erro);
            });
        },
		loadCss(){
			for (let i = 0; i < this.farmaceuticas.length; i++) {
				require(`../styles/${this.farmaceuticas[i].css}`);

			}
		}
		/*
		resetData() {
			roomsRef.get().then(val => {
				val.forEach(async val => {
					const ref = roomsRef.doc(val.id).collection('messages')

					await ref.get().then(res => {
						if (res.empty) return
						res.docs.map(doc => ref.doc(doc.id).delete())
					})

					roomsRef.doc(val.id).delete()
				})
			})

			usersRef.get().then(val => {
				val.forEach(val => {
					usersRef.doc(val.id).delete()
				})
			})
		},
		logout(){
			this.$parent.user = null
			firebase.auth().signOut();
		},
		async addData() {
			this.updatingData = true

			const user1 = this.users[0]
			await usersRef.doc(user1._id).set(user1)

			const user2 = this.users[1]
			await usersRef.doc(user2._id).set(user2)

			const user3 = this.users[2]
			await usersRef.doc(user3._id).set(user3)

			await roomsRef.add({
				users: [user1._id, user2._id],
				lastUpdated: new Date()
			})
			await roomsRef.add({
				users: [user1._id, user3._id],
				lastUpdated: new Date()
			})
			await roomsRef.add({
				users: [user2._id, user3._id],
				lastUpdated: new Date()
			})
			await roomsRef.add({
				users: [user1._id, user2._id, user3._id],
				lastUpdated: new Date()
			})

			this.updatingData = false
		}
		*/
	}
}
</script>

<style lang="scss" scoped>
.no-farma{
    padding-top: 50px;
}
.modal-no-farma{
    max-width: 500px;
    box-shadow: 3px 3px 8px 0px #ccc;
    margin: auto;
    padding: 10px;
}
.btn-sair{
	//color:#fff;
	background-color: #e2e4e5;
}
</style>

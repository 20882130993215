<template>
	<div class="vac-message-files-container">
		<div v-if="message_type==4 && Object.keys(this.message.obs).length > 0">
			<message-file
					:message_type="message_type"
					:file="message.obs"
					:current-user-id="currentUserId"
					:id_case="message.id_case"
					:tag="message.tag"
					:message="message"
					@filterChatTag="filterChatTag"
					@open-file="$emit('open-file', $event)"
				>
					<template v-for="(i, name) in $scopedSlots" #[name]="data">
						<slot :name="name" v-bind="data" />
					</template>
			</message-file>
		</div>
		<div v-else>
			<div v-if="imageVideoFiles">
				<message-file
					:message_type="message_type"
					:file="message.obs"
					:id_case="message.id_case"
					:tag="message.tag"
					:current-user-id="currentUserId"
					:message="message"
					@filterChatTag="filterChatTag"
					@open-file="$emit('open-file', $event)"
				>
					<template v-for="(i, name) in $scopedSlots" #[name]="data">
						<slot :name="name" v-bind="data" />
					</template>
				</message-file>
			</div>

			<div v-if="otherFiles">
				<div class="vac-file-container" @click.stop="openFile(message.obs, 'download')">
					<div class="vac-svg-button">
						<slot name="document-icon">
							<svg-icon name="document" />
						</slot>
					</div>
					<div class="vac-text-ellipsis">
						{{ message.obs.file.extension }}
					</div>
					<!--div v-if="message.obs.file.extension" class="vac-text-ellipsis vac-text-extension">
						{{ message.obs.file.extension }}
					</div-->
				</div>
			</div>
		</div>

		<format-message
			:file="message.obs"
			:content="message.message"
			:users="roomUsers"
			:text-formatting="textFormatting"
			:link-options="linkOptions"
			@open-user-tag="$emit('open-user-tag')"
		>
			<template v-for="(i, name) in $scopedSlots" #[name]="data">
				<slot :name="name" v-bind="data" />
			</template>
		</format-message>
	</div>
</template>

<script>
import SvgIcon from '../../../components/SvgIcon/SvgIcon'
import FormatMessage from '../../../components/FormatMessage/FormatMessage'

import MessageFile from '../MessageFile/MessageFile'

const { isImageVideoFile } = require('../../../utils/media-file')

export default {
	name: 'MessageFiles',
	components: { SvgIcon, FormatMessage, MessageFile },

	props: {
		message_type:{type: [String, Number], required: true},
		currentUserId: { type: [String, Number], required: true },
		message: { type: Object, required: true },
		roomUsers: { type: Array, required: true },
		textFormatting: { type: Boolean, required: true },
		linkOptions: { type: Object, required: true }
	},

	emits: ['open-file', 'open-user-tag'],

	computed: {
		imageVideoFiles() {
			//console.log(Object.keys(this.message.obs));
			return Object.keys(this.message.obs).length > 0 && isImageVideoFile(this.message.obs.file)
		},
		otherFiles() {
			return Object.keys(this.message.obs).length > 0 && !isImageVideoFile(this.message.obs.file)
			//return this.message.obs.filter(file => !isImageVideoFile(file))
		}
	},

	methods: {
		openFile(file, action) {
			this.$emit('open-file', { file, action })
		},
		filterChatTag(tag){
			this.$emit('filterChatTag', tag)
		},
	}
}
</script>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import vueMixin from './mixin';
import VueParticles from 'vue-particles'
import VueTheMask from 'vue-the-mask'
import Vuelidate from 'vuelidate'
import Multiselect from 'vue-multiselect'

Vue.use(VueParticles)
Vue.use(VueTheMask)
Vue.use(Vuelidate)

Vue.component('multiselect', Multiselect)

Vue.prototype.$eventBus = new Vue();

Vue.config.productionTip = false

window.axios = axios

Vue.mixin(vueMixin)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

<template>
	<div class="user-invite">

	</div>
</template>

<script>
export default {
	props: {
		invite: String
	},
	mounted(){
		this.inviteUser(this.invite)
	},
}
</script>

<style lang="scss" scoped>
.user-invite{
    padding-top: 50px;
}
.modal-user-invite{
    max-width: 500px;
    box-shadow: 3px 3px 8px 0px #ccc;
    margin: auto;
    padding: 10px;
}
</style>

<template>
    <div class="list-case">
		<div class="modal fade" id="caseListModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="caseListModalLabel">Lista de Casos</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="case-container">
							<div class="case-filter">
								<div class="case-types">
									<!--
									<a href="#">Abertos</a>
									<a href="#">Fechados</a>
									<a href="#">Todos</a>
									-->
									<ul class="cases-rdb">
									<li>
										<span>Casos:</span>
									</li>

									<li>
										<input type="radio" id="rdb-aberto" name="rdp-case" value="1" v-model="case_value" />
										<label for="rdb-aberto">Abertos</label>
									</li>
									<li>
										<input type="radio" id="rdb-fechado" name="rdp-case" value="2" v-model="case_value" />
										<label for="rdb-fechado">Fechados</label>
									</li>
									<li>
										<input type="radio" id="rdb-todos" name="rdp-case" value="0" v-model="case_value" />
										<label for="rdb-todos">Todos</label>
									</li>
									</ul>
								</div>
								<div class="text-end">
									<input type="text" placeholder="Buscar..." class="form-control form-control-sm ms-auto" v-model="case_filter">
								</div>
							</div>
							<div class="mt-3">
								<table>
									<thead>
										<tr>
											<th>Caso/Tags</th>
											<th>Data</th>
											<th>Criador</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="caso in filteredList" :key="caso.id" :data-id="caso.id_case">
											<td>
												<a href="#" @click.prevent.stop="filterChatTag({id_case: caso.id_case, tag: caso.tags})">{{caso.tags}}</a>
											</td>
											<td>{{getCaseDate(caso.dt_abertura)}}</td>
											<td>
												{{caso.criador}}
												<span id="edit-icon" @click="editCase(caso.id_case)" v-if="caso.flg_edit == 1">
													<slot name="modal-edit-pencil">
														<svg-icon name="modal-edit-pencil" />
													</slot>
												</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

						</div>
					</div>
					<div class="modal-footer justify-content-start">
						<div class="modal-case-actions">
							<button class="btn" @click="createCase">Criar caso novo</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ModalFormCase
			:currentFarmaId="currentFarmaId"
			:modalCaseFormType="modalCaseFormType"
			:modalCaseFormIdCase="modalCaseFormIdCase"
			:roomId="roomId"
			@sendMessage="sendMessage"
			@closeCaseModalForm="closeCaseModalForm"
			@updateCaseList="updateCaseList"
		></ModalFormCase>
    </div>
</template>

<script>
import axios from "axios"
import moment from "moment"
import { API, language_padrao } from '../utils/constants'
import SvgIcon from '../components/SvgIcon/SvgIcon'
import ModalFormCase from './ModalFormCase.vue'
export default {
	components:{
		SvgIcon,
		ModalFormCase
	},
	props:{
		currentFarmaId:{type: String, required:true},
		roomId:{type: String, required:true}
	},
	data(){
		return {
			modal: null,
			modalCaseForm:null,
			modalCaseFormType:'inserir',
			modalCaseFormIdCase:'0',
			loadingList: true,
			case_filter: "",
			case_value:'1',
			caseList: []
		}
	},
	watch:{
		case_value: function(){
			this.caseList = []
			this.loadingList = true
			this.getCaseList()
		}
	},
	computed:{
		filteredList(){
			var list = this.caseList
			var filteredList = list.filter(
				item => item.id_case.includes(this.case_filter.toLowerCase())
				|| item.tags.toLowerCase().includes(this.case_filter.toLowerCase())
				|| this.getCaseDate(item.dt_abertura).includes(this.case_filter)
				|| item.criador.toLowerCase().includes(this.case_filter.toLowerCase())
			)
			return filteredList
		},
	},
	mounted(){
		const modal = document.querySelector("#caseListModal")
		this.modal = new window.bootstrap.Modal(modal)

		modal.addEventListener("show.bs.modal", ()=>{
			console.log("show");
			this.getCaseList()

		})

		modal.addEventListener("hide.bs.modal", ()=>{
			console.log("hide");
			this.caseList = []
			this.loadingList = true
		})
	},
	methods:{
		getCaseList(){
			var self = this
            var settings = {
                "url": API+"cases/",
                "method": "GET",
                "headers": {
                "X-Auth-Token": localStorage.getItem("api_token"),
                "X-Id-Language": language_padrao,
				"X-Farma": this.currentFarmaId,
                "X-Case": '0',
				"X-CaseStatus": self.case_value
                },
            };

            axios(settings).then(response=>{
				self.caseList = response.data.list
				self.loadingList = false
            }).catch(function(erro) {
                console.log(erro);
            });
		},
		createCase(){
			this.modalCaseFormType = 'inserir'
			this.modalCaseFormIdCase = '0'
			this.openModalCaseForm()
		},
		editCase(id_case){
			this.modalCaseFormType = 'alterar'
			this.modalCaseFormIdCase = id_case
			this.openModalCaseForm()
		},
		updateCaseList(){
			this.getCaseList()
		},
		filterChatTag(tag){
			this.$emit('closeCaseModal')
			this.$emit('filterChatTag', tag)
		},
		sendMessage(mensagem){
			var content = mensagem.mensagem
			var roomId = this.roomId
			var files = null
			var replyMessage = null
			var message_type = 1
			var message_tag = "case_tag"
			var options = {message_tag:message_tag, id_case:mensagem.id_case, tag: mensagem.tag}
			this.$emit("sendMessage", { content, roomId, files, replyMessage }, message_type, options)
			this.$emit('closeCaseModal')
		},
		openModalCaseForm(){
			const modal = document.querySelector("#caseFormModal")
			this.modalCaseForm = new window.bootstrap.Modal(modal)
			this.modalCaseForm.show()
		},
		closeCaseModalForm(){
			this.modalCaseForm.hide()
		},
		getCaseDate(date){
			return moment(date).format('DD/MM/YYYY')
		}
	}
}
</script>


<style scoped>
.list-case{
	font-size: 14px;
}
.cases-rdb {
	list-style-type: none;
	padding: 0;
}

.cases-rdb li {
	float: left;
    margin: 0 5px 0 0;
    width: 90px;
    height: 30px;
    position: relative;
	cursor: pointer;
}
.cases-rdb li:first-child {
	line-height: 2;
	width: 55px !important;
}
.cases-rdb label,
.cases-rdb input {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	cursor: pointer;
}

.cases-rdb input[type="radio"] {
	opacity: 0.01;
	z-index: 100;
}

.cases-rdb input[type="radio"]:checked+label,
.Checked+label {
	background-color: #ddd;
	color: #333;
}

.cases-rdb label {
	padding: 5px;
	cursor: pointer;
	z-index: 90;
	line-height: 1.4;
    text-align: center;
	background-color: var(--chat-notify-button-bg-color);
	color: var(--chat-notify-button-color);
	border-radius: 50px;
}

.cases-rdb label:hover {
	background: #DDD;
}

.case-filter{
	display: grid;
	grid-template-columns: 60% 40%;
}
.case-types a {
    margin-right: 10px;
}

table{
	width: 100%;
}

tr {
  border-bottom: 1px solid #ddd;
}

th {
    border: 1px solid;
}

td {
    border: 1px solid;
}

#edit-icon{
	float: right;
	cursor:pointer;
}

.modal-case-actions button{
	background-color: var(--chat-notify-button-bg-color);
	color: var(--chat-notify-button-color);
	border-radius: 50px;
}
@media only screen and (max-width: 768px) {
	.case-filter {
		display: block;
	}
	.cases-rdb li[data-v-75d18c28]:first-child {
		width: 100% !important;
	}
}
</style>

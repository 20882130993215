// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyDNuIgT1M4usve22NWXGQQuFk5BYFJ0nqw",
	authDomain: "join-sciences.firebaseapp.com",
	projectId: "join-sciences",
	storageBucket: "join-sciences.appspot.com",
	messagingSenderId: "701629882213",
	appId: "1:701629882213:web:0f95c86a28bed5c1f64b8c",
	measurementId: "${config.measurementId}"
};

// Initialize Firebase
initializeApp(firebaseConfig);



import axios from 'axios';
import { API, id_app, language_padrao, version_app } from './utils/constants'
import {getAuth, signOut} from 'firebase/auth'
import moment from 'moment'
//const { parseTimestamp } = require('./utils/dates')
export default {
    methods:{
        fcLogin: function(user){
            var settings = {
                "url": API + "user/",
                "method": "POST",
                "headers": {
                    "Content-Type": "application/json"
                },
                "data": JSON.stringify({
                    "id_app": id_app,
                    "id_language": language_padrao,
                    "name": user.displayName.replace(/[<|>|(|)|']/gi, ""),
                    "display_name": user.displayName.replace(/[<|>|(|)|']/gi, ""),
                    "app_version": version_app,
                    "device_id": /*device.uuid*/'N/A',
                    "device_name": /*device.name.replace(/[<|>|(|)|']/gi, "")*/'N/A',
                    "device_type": /*device.platform*/'N/A',
                    "device_version": /*device.version*/'N/A',
                    "device_other": "Nenhum",
                    "device_language": "3",
                    "utc_time": this.UtcTime(),
                    "player_id": localStorage.getItem("pushuserid") || '',
                    "token_authentication": user.uid
                }),
            };

            return axios(settings);
        },
        fcUserComplete: function(tipo, user){
            var _this = this;
            if(tipo==='first-access'){
				_this.inviteUser(localStorage.getItem('invite'), false)
                this.$router.push({name:'Email-Verificacao', params: {email: user.email}})
                return false
            }
            var settings = {
                "url": API+"user/detail/",
                "method": "GET",
                "headers": {
                "X-Auth-Token": localStorage.getItem('api_token'),
                "X-Id-Language": language_padrao,
                "X-Id-APP": id_app
                },
              };

            axios(settings).then(function (response) {
               if(response.status===200){
					if (localStorage.getItem('invite') && (_this.$route.path == '/login' || _this.$route.path == '/login-celular' || _this.$route.path == '/login-email')) {
						_this.$router.push('/'+localStorage.getItem('invite'))
					}else if (_this.$route.path == '/registro') {
						_this.inviteUser(localStorage.getItem('invite'))
					}else{
						_this.$router.push('/')
					}
               }else if(response.status===496){
					if (_this.$route.path == '/registro') {
						window.location.href = '/'
					}
                   var tiponew = tipo=='email' ? tipo : 'oauth';
                   //window.location = 'loginnew.html?tipo='+tiponew+'&email=' + user.email + '&nome=' + user.displayName
                   _this.$router.push({name:'Registro', params:{tipo:tiponew, email: user.email, nome: user.displayName}})
               }else{
                _this.$router.push('/login')
               }
            }).catch(function (erro) {
                console.log(erro.response.status);
                if(erro.response.status===496){
					if (_this.$route.path == '/registro') {
						window.location.href = '/'
					}
                    var tiponew = tipo=='email' ? 'email' : 'oauth';
                    //window.location = 'loginnew.html?tipo='+tiponew+'&email=' + user.email + '&nome=' + user.displayName
                    _this.$router.push({name:'Registro', params:{tipo:tiponew, email: user.email, nome: user.displayName}})
                }else{
                    _this.$router.push('/login')
                }
                console.log(JSON.stringify(erro))
            });
        },
		logout(){
			//var self = this
            const auth = getAuth();
            signOut(auth).then(() => {
                localStorage.removeItem("api_token")
                localStorage.removeItem("api_token_reset")
                //self.isLogged = false
                window.location.reload()
            // Sign-out successful.
            }).catch((error) => {
				console.log(error);
            // An error happened.
            });
		},
		inviteUser(invite, redirect=true){
			var self = this
            var settings = {
                "url": API+"invite/",
                "method": "GET",
                "headers": {
                "X-Auth-Token": localStorage.getItem("api_token"),
                "X-Id-Language": language_padrao,
                "X-Invite": invite
                },
            };

            axios(settings).then(()=>{
				localStorage.setItem('invite', '')
				if (redirect) {
					self.$router.push('/')
				}
            }).catch(function(erro) {
                console.log(erro);
            });
		},
        reLogin: function(){
            var settings = {
                "url": API+"auth/",
                "method": "PUT",
                "headers": {
                  "X-Auth-Token": localStorage.getItem("api_token"),
                  "X-Auth-Token-Reset": localStorage.getItem("api_token_reset"),
                  "X-Id-Language": language_padrao,
                  "X-Id-APP": id_app,
                  "accept": "application/json",
                  "Content-Type": "application/json"
                },
                "data": JSON.stringify({"app_version":version_app,"player_id":localStorage.getItem("pushuserid") || ''}),
              };

             return axios(settings)
        },
        UtcTime(){
            var d = new Date();
            var n = d.getTimezoneOffset();
            var var_utc_time = (n / 60) * -1;
            return var_utc_time.toString();
        },
		dateFormat(date, format){
			//let datetime = `${date.replaceAll('-', '/')} UTC`
			let datetimeutc = moment.utc(date)
			//console.log(datetimeutc.local().format('L'));
			if (format == "DD/MM/YYYY") {
				return datetimeutc.local().format('DD/MM/YYYY')
			}else if(format == 'HH:mm'){
				return datetimeutc.local().format('HH:mm')
			}
		},

		isSameDay(date){
			//date = `${date.replaceAll('-', '/')} UTC`
			let datetimeutc = moment.utc(date)
            //let hoje = moment().format('L')
            return datetimeutc.local().format('L') == moment().format('L') ? "Hoje" : datetimeutc.local().format('DD/MM/YYYY')
		},
    }
}

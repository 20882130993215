export const IMAGE_TYPES = ['png', 'jpg', 'jpeg', 'webp', 'svg', 'gif']
export const VIDEO_TYPES = ['mp4', 'video/ogg', 'webm', 'quicktime']
export const AUDIO_TYPES = ['mp3', 'audio/ogg', 'wav', 'mpeg']
export const API = "https://joinsciences.pvmktg.com.br/api/"
//export const API = "https://joinscienceshomolog.pvmktg.com.br/api/"
export const id_app = 2
export const version_app = "1.0.0"
export const language_padrao = 1
export var user_uid = ''

export function changeParam(param, value){
	param = value
}

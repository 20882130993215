<template>
	<div class="login-page reset-password">
		<div class="modal-reset-password">
            <form @submit.prevent="fcSendEmail">
                <div class="mb-3">
                    <label for="txtemail" class="form-label">Email</label>
                    <input type="email" class="form-control form-control-sm" id="txtEmail" name="txtEmail" v-model="email" required>
                </div>
                <br><br>
				<button type="button" class="btn-voltar btn btn-sm" @click="$router.push('/login')">Voltar</button>
                <button type="submit" class="btn-send btn btn-sm float-end">Enviar Email</button>
            </form>
        </div>
	</div>
</template>

<script>
	import {
		getAuth,
		sendPasswordResetEmail
	} from "firebase/auth";
	export default {
		data(){
			return{
				email: ""
			};
		},
		methods: {
			fcSendEmail() {
				const auth = getAuth();
				sendPasswordResetEmail(auth, this.email)
					.then(() => {
						alert(`Email enviado para ${this.email}. Por favor, acesse o email informado para redefinição de senha.`);
						this.$router.push('/login-email')
					})
					.catch(erro => {
						console.log(erro);
						if(erro.code === 'auth/user-not-found'){
                            alert('Este e-mail não existe em nossa base de dados.');
                        }else{
                            alert(erro.message);
                        }
					})

			}
		}
	}
</script>

<style scoped>
.reset-password{
    padding-top: 50px;
	font-size: .9em;
}
.modal-reset-password{
    max-width: 500px;
    box-shadow: 3px 3px 8px 0px #ccc;
    margin: auto;
    padding: 25px;
	background-color: #fafafa;
}
.form-control{
	font-size: .9em;
}
.btn-send{
	color: #fff;
	background-color: #5aa5ef;
}
.btn-voltar{
	color: #fff;
	background-color: #e57171;
}
</style>

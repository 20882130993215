<template>
    <div class="login-page login-form-email">
        <div class="modal-login-email">
            <form>
                <div class="mb-3">
                    <label for="txtemail" class="form-label">Email</label>
                    <input type="email" class="form-control form-control-sm" id="txtEmail" name="txtEmail" v-model="loginComponent.email" required>
					<div class="input-error text-danger" v-if="submitStatus && !$v.loginComponent.email.required">
						&nbsp;&nbsp;* Campo Obrigatório
					</div>
					<div class="input-error text-danger" v-if="submitStatus && !$v.loginComponent.email.email">
						&nbsp;&nbsp;* Email inválido
					</div>
				</div>
                <div class="mb-3">
                    <label for="txtsenha" class="form-label">Senha</label>
                    <input type="password" class="form-control form-control-sm" id="txtSenha" name="txtSenha" v-model="loginComponent.senha" required>
					<div class="input-error text-danger" v-if="submitStatus && !$v.loginComponent.senha.required">
						&nbsp;&nbsp;* Campo Obrigatório
					</div>
                </div>
                <router-link to="/recuperar-senha">Esqueceu a senha?</router-link>
                <router-link class="float-end" to="/registro">Não possuo conta</router-link>
                <br><br>
				<button type="button" class="btn-voltar btn btn-sm" @click="$router.push('/login')">Voltar</button>
                <button type="button" class="btn-login btn btn-sm float-end" @click="submitForm">Entrar</button>
            </form>
        </div>
    </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import {
    getAuth,
    signInWithEmailAndPassword,
    fetchSignInMethodsForEmail
} from "firebase/auth";
export default {
    data(){
        return {
			submitStatus: false,
            loginComponent:{
                email:'',
                senha: '',
            },
        }
    },
	validations: {
		loginComponent: {
			email:{
				required,
				email
			},
			senha: {
				required
			}
		}
	},
    methods:{
		submitForm(){
			this.$v.loginComponent.$touch()
			if (this.$v.loginComponent.$invalid) {
				this.submitStatus = true
				return;
			}else{
				this.Login()
			}
		},
        Login() {
            var _this = this;
            const auth = getAuth()
            //VALIDAR FORMULARIO
            fetchSignInMethodsForEmail(auth, _this.loginComponent.email)
                .then(function(result) {
                    var tp = result;
                    signInWithEmailAndPassword(auth, _this.loginComponent.email, _this.loginComponent.senha)
                    .then(function(result) {
                        var user = result.user;

                        if(user.emailVerified){
                            _this.fcLogin(user).then(function(response){
                                localStorage.setItem("api_token", response.data.list.api_token);
                                localStorage.setItem("api_token_reset", response.data.list.api_token_reset);
                                _this.fcUserComplete('email', user);
                            }).catch(function(erro){
                                console.log(erro.responseJSON.errors);
                            });
                        }else{
                            _this.$router.push({name:'Email-Verificacao', params: {email: user.email}})
                        }
                    }).catch(function(erro) {
                        // Erros se o e-mail não existe ou senha errada.
                        console.log(erro);
                        if(erro.code === 'auth/user-not-found'){
                            alert('Este e-mail não existe em nossa base de dados.');
                        }else if(erro.code === 'auth/wrong-password'){
                            if(tp == 'password'){
                                alert('Senha inválida, tente novamente.')
                            }else{
                                alert('Este e-mail é usando em outro método de Login.');
                            }
                        }else{
                            alert(erro.message);
                        }
                    });
                }).catch(function(erro) {
                    // Erro ao verificar se e-mail existe
                    console.log(JSON.stringify(erro));
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.login-form-email{
    padding-top: 50px;
	font-size: .9em;
}
.modal-login-email{
    max-width: 500px;
    box-shadow: 3px 3px 8px 0px #ccc;
    margin: auto;
    padding: 10px;
	background-color: #fafafa;
}
.form-control{
	font-size: .9em;
}
.btn-login{
	color: #fff;
	background-color: #5aa5ef;
}
.btn-voltar{
	color: #fff;
	background-color: #e57171;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import LoginEmail from '../views/LoginEmail.vue'
import LoginCelular from '../views/LoginCelular.vue'
import Registro from '../views/Registro.vue'
import ResetSenha from '../views/ResetSenha.vue'
import EmailVerificacao from '../views/EmailVerificacao.vue'
import UserInvite from '../views/UserInvite.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/login-email',
    name: 'Login-Email',
    component: LoginEmail
  },
  {
    path: '/login-celular',
    name: 'Login-Celular',
    component: LoginCelular
  },
  {
    path: '/registro',
    name: 'Registro',
    component: Registro
  },
  {
    path: '/recuperar-senha',
    name: 'Recuperar Senha',
    component: ResetSenha
  },
  {
    path: '/email-verificacao',
    name: 'Email-Verificacao',
    component: EmailVerificacao
  },
  {
    path: '/:invite',
    name: 'invite',
    component: UserInvite,
    props: true,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,
  routes
})

export default router

<template>
	<div class="notification-message">
		<div class="modal fade" id="modalNotify" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalNotifyLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-scrollable">
				<div class="modal-content" v-if="content">
					<div class="modal-header">
						<h5 class="modal-title" id="modalNotifyLabel">{{farma}}</h5>
					</div>
					<div class="modal-body" v-html="decodeURIComponent(content.texto)">
					</div>
					<div class="modal-footer">
						<div class="row m-auto">
							<div class="col-xs-12 col-sm-4 py-1 d-flex justify-content-center">
								<button type="button" class="btn" data-bs-dismiss="modal">Fechar</button>
							</div>
							<div class="col-xs-12 col-sm-4 py-1 d-flex justify-content-center">
								<button type="button" class="btn" @click="postModal('2')" >Lembrar depois</button>
							</div>
							<div class="col-xs-12 col-sm-4 py-1 d-flex justify-content-center">
								<button type="button" class="btn" @click="postModal('1')">Aceito</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import { API, language_padrao } from '../../utils/constants'
export default {
    props: {
        currentFarmaId: {
            type: String,
            required: true
        },
        farmas: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            visible: false,
            farma: '',
            content: null,
            modal: null
        }
    },
    watch: {
        currentFarmaId(val) {
            if (val) {
                this.getModal()
            }
        }
    },
    mounted() {
        this.getModal()
    },
    methods: {
        getModal() {
            var self = this
            let farma = self.farmas.filter(item => item.id_farma == this.currentFarmaId)
            self.farma = farma[0].farma

            var settings = {
                "url": API + "obs/",
                "method": "GET",
                "headers": {
                    "X-Auth-Token": localStorage.getItem("api_token"),
                    "X-Id-Language": language_padrao,
                    "X-Farma": farma[0].id_farma
                },
            };

            axios(settings).then(response => {
                let content = response.data.list
                if (content.length) {
                    self.content = content[0]
                    self.id_popup = content[0].id
                    self.modal = new window.bootstrap.Modal(document.getElementById('modalNotify'))
                    self.modal.show()
                }
            }).catch(function(erro) {
                console.log(erro);
            });
        },
        postModal(info) {

            var self = this
            let farma = self.farmas.filter(item => item.id_farma == this.currentFarmaId)
            var tipo_acao = parseInt(info)
            self.farma = farma[0].farma

            var settings = {
                "url": API + "obs/",
                "method": "POST",
                "headers": {
                    "Content-Type": "application/json",
                    "X-Auth-Token": localStorage.getItem("api_token"),
                    "X-Id-Language": language_padrao,
                },
                "data": JSON.stringify({
                    "id_popup": self.content.id,
                    "acao": tipo_acao
                }),
            };
            axios(settings).then(response => {
                if (response.status === 200) {
                    this.modal.hide()
                }
            }).catch(function(erro) {
                console.log(erro);
            });

        },
        displayNotification() {
            var self = this
            this.notifications_length++
            this.notifications.unshift({
                id: this.notifications_length,
                notification: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
            })
            setTimeout(() => {
                self.notifications.pop()
            }, 2000);

        },
    }
}
</script>

<style lang="scss" scoped>
.modal-content{
    font-size: 14px;
	background-color: var(--chat-notify-bg-color);
	color: var(--chat-notify-color);
	.btn-close-modal{
		box-sizing: content-box;
		background: transparent;
		border: 0;
		border-radius: 0.25rem;
		font-size: 1.2em;
		color: var(--chat-notify-color);
	}
	.btn{
		background-color: var(--chat-notify-button-bg-color);
		color: var(--chat-notify-button-color);
        font-size: 12px;
        width: 90px;
	}
}
</style>

<template>
	<div class="image-preview">
		<div class="modal fade" id="imagepreviewmodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-body">
						<button type="button" class="btn-close" @click="closeModal"></button>
						<img :src="url" class="imagepreview" style="width: 100%;" >
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props:{
		showPreview:{type: Boolean, required: true},
		url:{type: String, required: true},
	},
	data(){
		return{
			myModal: null
		}
	},
	mounted(){
		this.myModal = new window.bootstrap.Modal(document.getElementById('imagepreviewmodal'))
		this.myModal.show()
	},
	methods:{
		closeModal(){
			this.myModal.hide()
			this.$emit('close-modal-preview')

		}
	}
}
</script>

<style>

</style>

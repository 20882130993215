<template>
  <div id="app">
    <router-view/>
	<vue-particles
		v-if="showParticles"
		color="#333"
		:particleOpacity="0.7"
		:particlesNumber="100"
		shapeType="circle"
		:particleSize="4"
		linesColor="#333"
		:linesWidth="1"
		:lineLinked="true"
		:lineOpacity="0.4"
		:linesDistance="150"
		:moveSpeed="3"
		:hoverEffect="true"
		hoverMode="grab"
		:clickEffect="true"
		clickMode="repulse"
	>
	</vue-particles>
  </div>
</template>

<script>
import axios from 'axios'
import './firebase/firebase-init'
import {getAuth, onAuthStateChanged} from 'firebase/auth'
import { API, id_app, language_padrao } from './utils/constants'

export default {
	computed:{
		showParticles(){
			let result = true;
			let page = this.$route.name;
			if (page == "Home") {
				result = false;
			}
			return result;
		}
	},
	mounted(){
		localStorage.setItem('invite', '')
		this.checkLogin()
		this.$eventBus.$on('checkLogin', this.checkLogin)
	},
	methods:{
		checkLogin(){
			var self = this
            const auth = getAuth();
            const unsubscribe = onAuthStateChanged(auth, (user) => {
				if (self.$route.params.invite) {
					localStorage.setItem('invite', self.$route.params.invite)
				}
                if (!user) {
                    //self.isLogged = false;
                    if(this.$route.path != '/login'){
                        this.$router.push('/login')
                    }
                }
                else{
                    self.showPage == true
                    if (user.providerData[0].providerId == "password" && !user.emailVerified) {
                        if (this.$route.path != '/email-verificacao') {
                            this.$router.push({name:'Email-Verificacao', params: {email: user.email}})
                        }
                    }else{
                        var settings = {
							"url": API+"user/detail/",
							"method": "GET",
							"headers": {
							"X-Auth-Token": localStorage.getItem("api_token"),
							"X-Id-Language": language_padrao,
							"X-Id-APP": id_app
							},
						};

						axios(settings).catch(function(erro) {
							if(erro.response.status === 498){
								self.reLogin().then(function(response){
									localStorage.setItem("api_token", response.data.list.api_token);
									localStorage.setItem("api_token_reset", response.data.list.api_token_reset);
									window.location.reload()
								}).catch(function(erro){
									//self.isLogged = false;
									console.log(erro);
									this.$router.puth('/login')
								})
							}else{
								self.fcUserComplete('oauth',user);
							}
						});
                    }

                }

            });
            unsubscribe();
		}
	}
}
</script>

<style lang="scss">
body {
	background: #fafafa;
	margin: 0;
	font-family: 'Quicksand', sans-serif;
}

input {
	-webkit-appearance: none;
}
/*
.form-control:focus{
	box-shadow: none !important;
}
*/

#particles-js{
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.vac-avatar, .vac-svg-button.vac-list-room-options{
  display: none !important;
}

.vac-room-name{
  font-size: .9em !important;

}

.vac-room-category{
  font-size: .8em;
  line-height: 25px;
  color: #b3b3b3;
}

.app-container {
	padding: 5px 30px;
}

.app-mobile {
	padding: 0;

	&.app-mobile-dark {
		background: #131415;
	}

	.filter-select {
		margin: 10px 5px 0 10px;
	}

	select {
		margin: 10px 0;
	}

	.button-theme {
		margin: 10px 10px 0 0;

		.button-github {
			height: 23px;

			img {
				height: 23px;
			}
		}
	}
}

.filter-select {
	font-size: 14px;
	margin-right: 5px;
	margin-top: 10px;

	&.filter-select-dark {
		color: #fff;
	}
}

select {
	//height: 20px;
	padding: 2px;
	outline: none;
	border: 1px solid #e0e2e4;
	border-radius: 4px;
	background: #fff;
	margin-bottom: 20px;
	font-size: 14px;
	vertical-align: sub;
}

.filter-select.farmaceuticas, .filter-select.ordenar{
  margin-left: 30px;
  vertical-align: sub;
}

.farma-logo{
  vertical-align: middle;
}

.farma-logo img{
  width: 100px;
}

.button-theme {
	float: right;
	display: flex;
	align-items: center;

	.button-light {
		background: #fff;
		border: 1px solid #46484e;
		color: #46484e;
	}

	.button-dark {
		background: #1c1d21;
		border: 1px solid #1c1d21;
	}

	button {
		color: #fff;
		outline: none;
		cursor: pointer;
		border-radius: 4px;
		padding: 6px 12px;
		margin-left: 10px;
		border: none;
		font-size: 14px;
		transition: 0.3s;
		vertical-align: middle;

		&.button-github {
			height: 30px;
			background: none;
			padding: 0;
			margin-left: 20px;

			img {
				height: 30px;
			}
		}

		&:hover {
			opacity: 0.8;
		}

		&:active {
			opacity: 0.6;
		}

		@media only screen and (max-width: 768px) {
			padding: 3px 6px;
			font-size: 13px;
		}
	}
}

.version-container {
	padding-top: 20px;
	text-align: right;
	font-size: 14px;
	color: grey;
}
</style>

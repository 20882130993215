<template>
    <div class="list-participants">
		<div class="modal fade" id="participantsListModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="participantsListModalLabel">Lista de Participantes</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="participants-container">
							<div class="row participants-filter">
								<div class="col-md-6 offset-md-6">
									<input type="text" placeholder="Buscar..." class="form-control form-control-sm ms-auto" v-model="participants_filter">
								</div>
							</div>
							<div class="mt-3">
								<table>
									<thead>
										<tr>
											<th>Participantes</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(participante, key) in filteredList" :key="key">
											<td>
												{{participante.nome_display}}
											</td>
										</tr>
									</tbody>
								</table>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
import axios from 'axios'
import { API, language_padrao } from '../utils/constants'
export default {
	props:{
		currentFarmaId:{type: String, required:true},
		roomId:{type: String, required:true}
	},
	data(){
		return {
			modal: null,
			loadingList: true,
			participants_filter: "",
			participantsList: []
		}
	},
	computed:{
		filteredList(){
			var list = this.participantsList
			var filteredList = list.filter(
				item => item.nome_display.toLowerCase().includes(this.participants_filter.toLowerCase())
			)
			return filteredList
		},
	},
	mounted(){
		const modal = document.querySelector("#participantsListModal")
		this.modal = new window.bootstrap.Modal(modal)

		modal.addEventListener("show.bs.modal", ()=>{
			console.log("show");
			this.getParticipantsList()
		})

		modal.addEventListener("hide.bs.modal", ()=>{
			console.log("hide");
			this.participantsList = []
			this.loadingList = true
		})
	},
	methods:{
		getParticipantsList(){
			let self = this;
			var settings = {
                "url": API + "rooms/obs/usersbyroom/",
                "method": "GET",
                "headers": {
                    "X-Auth-Token": localStorage.getItem('api_token'),
                    "X-Id-Language": language_padrao,
					"X-Id-Room": this.roomId,
                    "Content-Type": "application/json"
                },
            };
            axios(settings).then(function(response) {
				self.participantsList = response.data.list
            }).catch(function(erro) {
                console.log(erro);
            });
		}
	}
}
</script>


<style scoped>
table{
	width: 100%;
}

tr {
	border-bottom: 1px solid #ddd;
}

th {
    border: 1px solid;
}

td {
    border: 1px solid;
}
</style>

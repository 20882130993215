<template>
	<div class="vac-room-container">
		<slot name="room-list-item" v-bind="{ room }">
			<slot name="room-list-avatar" v-bind="{ room }">
				<div
					v-if="room.avatar"
					class="vac-avatar"
					:style="{ 'background-image': `url('${room.avatar}')` }"
				/>
			</slot>
			<div class="vac-name-container vac-text-ellipsis">
			<div class="vac-room-category vac-text-ellipsis" v-if="ordem!='recentes'">
				{{room.grupo}} 
			</div>
				<div class="vac-title-container">
					<div
						v-if="userStatus"
						class="vac-state-circle"
						:class="{ 'vac-state-online': userStatus === 'online' }"
					/>
					<div class="vac-room-name vac-text-ellipsis">
						{{ room.room }}
					</div>
					<div v-if="room" class="vac-text-date">
						{{ isSameDay(room.dt_recent) }}
					</div>
				</div>
				<div
					class="vac-text-last"
					:class="{
						'vac-message-new':
							room.lastMessage && room.lastMessage.new && !typingUsers
					}"
				>
					<span>{{decodeURIComponent(room.msg_recente) || 'Nenhuma mensagem'}}</span>
				</div>
			</div>
		</slot>
	</div>
</template>

<script>
/*
import vClickOutside from 'v-click-outside'

import SvgIcon from '../../../components/SvgIcon/SvgIcon'
import FormatMessage from '../../../components/FormatMessage/FormatMessage'

import typingText from '../../../utils/typing-text'
const { isAudioFile } = require('../../../utils/media-file')
*/
export default {
	name: 'RoomsContent',
	/*
	components: {
		SvgIcon,
		FormatMessage
	},
	*/
	directives: {
		//clickOutside: vClickOutside.directive
	},

	props: {
		currentUserId: { type: [String, Number], required: true },
		room: { type: Object, required: true },
		textFormatting: { type: Boolean, required: true },
		linkOptions: { type: Object, required: true },
		textMessages: { type: Object, required: true },
		roomActions: { type: Array, required: true },
		ordem: {type: String, require: false}
	},

	emits: ['room-action-handler'],

	data() {
		return {
			roomMenuOpened: null
		}
	},
	computed: {

		getLastMessage() {
			const isTyping = this.typingUsers
			if (isTyping) return isTyping

			const content = this.room.msg_recente

			if (this.room.length <= 2) {
				return content
			}


			return `${content}`
		},

		userStatus() {
			if (!this.room.users || this.room.users.length !== 2) return

			const user = this.room.users.find(u => u._id !== this.currentUserId)
			if (user && user.status) return user.status.state

			return null
		},
		/*
		typingUsers() {
			return typingText(this.room, this.currentUserId, this.textMessages)
		},
		*/
		isMessageCheckmarkVisible() {
			return (
				!this.typingUsers &&
				this.room.lastMessage &&
				!this.room.lastMessage.deleted &&
				this.room.lastMessage.senderId === this.currentUserId &&
				(this.room.lastMessage.saved ||
					this.room.lastMessage.distributed ||
					this.room.lastMessage.seen)
			)
		},
		formattedDuration() {
			const file = this.room.lastMessage.files[0]

			if (!file.duration) {
				return `${file.name}.${file.extension}`
			}

			let s = Math.floor(file.duration)
			return (s - (s %= 60)) / 60 + (s > 9 ? ':' : ':0') + s
		},
		/*
		isAudio() {
			return this.room.lastMessage.files
				? isAudioFile(this.room.lastMessage.files[0])
				: false
		}
		*/
	},

	methods: {
		isDeleted(message){
			return (
				message.deleted && ((message.deleted_to == 'all') || (message.deleted_to.indexOf(this.currentUserId) != -1))
			)
		},
		isNotDeleted(message){
			return (
				!message.deleted || (message.deleted_to.indexOf(this.currentUserId) == -1)
			)
		},
		roomActionHandler(action) {
			this.closeRoomMenu()
			this.$emit('room-action-handler', { action, roomId: this.room.roomId })
		},
		closeRoomMenu() {
			this.roomMenuOpened = null
		}
	}
}
</script>

<template>
	<div :id="message.id_message" :ref="message.id_message" class="vac-message-wrapper">
		<div v-if="showDate()" class="vac-card-info vac-card-date">
			{{ dateFormat(message.data, 'DD/MM/YYYY') }}
		</div>

		<div v-if="newMessage.id_message === message.id_message" class="vac-line-new">
			{{ textMessages.NEW_MESSAGES }}
		</div>

		<div v-if="message.system" class="vac-card-info vac-card-system">
			<format-message
				:content="message.message"
				:users="roomUsers"
				:text-formatting="textFormatting"
				:link-options="linkOptions"
				@open-user-tag="openUserTag"
			>
				<template v-for="(i, name) in $scopedSlots" #[name]="data">
					<slot :name="name" v-bind="data" />
				</template>
			</format-message>
		</div>

		<div
			v-else
			class="vac-message-box"
			:class="{ 'vac-offset-current': message.position === 'Right'}"
		>
			<slot name="message" v-bind="{ message }">
				<div
					v-if="message.avatar && message.position === 'Left'"
					class="vac-avatar"
					:style="{ 'background-image': `url('${message.avatar}')` }"
				/>
				<div
					class="vac-message-container"
					:class="{
						'vac-message-container-offset': messageOffset
					}"
				>
					<div
						class="vac-message-card"
						:class="{
							'vac-message-highlight': isMessageHover,
							'vac-message-current': message.position === 'Right',
							'vac-message-deleted': isDeleted(message)
						}"
						@mouseover="onHoverMessage"
						@mouseleave="onLeaveMessage"

					>
						<div
							v-if="message.position === 'Left'"
							class="vac-text-username"
							:class="{
								'vac-username-reply': isNotDeleted(message) && message.replyMessage
							}"
						>
							<span>{{ message.nome }}</span>
						</div>

						<message-reply
							v-if="message.reply_msg != '' || Object.keys(message.reply_obs).length"
							:message="message"
							:room-users="roomUsers"
							:text-formatting="textFormatting"
							:link-options="linkOptions"
						>
							<template v-for="(i, name) in $scopedSlots" #[name]="data">
								<slot :name="name" v-bind="data" />
							</template>
						</message-reply>

						<div v-if="isDeleted(message)">
							<slot name="deleted-icon">
								<svg-icon name="deleted" class="vac-icon-deleted" />
							</slot>
							<span>{{ textMessages.MESSAGE_DELETED }}</span>
						</div>

						<format-message
							v-else-if="message.id_message_type == 1"
							:file="message.obs"
							:content="message.message || ''"
							:users="roomUsers"
							:text-formatting="textFormatting"
							:link-options="linkOptions"
							:id_case="message.id_case"
							:tag="message.tag"
							@filterChatTag="filterChatTag"
							@open-user-tag="openUserTag"
						>
							<template v-for="(i, name) in $scopedSlots" #[name]="data">
								<slot :name="name" v-bind="data" />
							</template>
						</format-message>

						<message-files
							v-else-if="!isAudio || message.id_message_type == 3"
							:message_type="message.id_message_type"
							:current-user-id="currentUserId"
							:message="message"
							:id_case="message.id_case"
							:tag="message.tag"
							:room-users="roomUsers"
							:text-formatting="textFormatting"
							:link-options="linkOptions"
							@filterChatTag="filterChatTag"
							@open-file="openFile"
						>
							<template v-for="(i, name) in $scopedSlots" #[name]="data">
								<slot :name="name" v-bind="data" />
							</template>
						</message-files>

						<template v-else>
							<audio-player
								:src="message.files[0].url"
								@update-progress-time="progressTime = $event"
								@hover-audio-progress="hoverAudioProgress = $event"
							>
								<template v-for="(i, name) in $scopedSlots" #[name]="data">
									<slot :name="name" v-bind="data" />
								</template>
							</audio-player>

							<div v-if="isNotDeleted(message)" class="vac-progress-time">
								{{ progressTime }}
							</div>
						</template>

						<div class="vac-text-timestamp">
							<div
								v-if="message.edited && isNotDeleted(message)"
								class="vac-icon-edited"
							>
								<slot name="pencil-icon">
									<svg-icon name="pencil" />
								</slot>
							</div>
							<span>{{ dateFormat(message.data, "HH:mm") }}</span>
							<span v-if="isCheckmarkVisible">
								<slot name="checkmark-icon" v-bind="{ message }">
									<svg-icon
										:name="
											message.distributed ? 'double-checkmark' : 'checkmark'
										"
										:param="message.seen ? 'seen' : ''"
										class="vac-icon-check"
									/>
								</slot>
							</span>
						</div>

						<message-actions
							:current-user-id="currentUserId"
							:message="message"
							:message-actions="messageActions"
							:room-footer-ref="roomFooterRef"
							:show-reaction-emojis="showReactionEmojis"
							:hide-options="hideOptions"
							:message-hover="messageHover"
							:hover-message-id="hoverMessageId"
							:hover-audio-progress="hoverAudioProgress"
							@hide-options="$emit('hide-options', false)"
							@update-message-hover="messageHover = $event"
							@update-options-opened="optionsOpened = $event"
							@update-emoji-opened="emojiOpened = $event"
							@message-action-handler="messageActionHandler"
							@send-message-reaction="sendMessageReaction"
						>
							<template v-for="(i, name) in $scopedSlots" #[name]="data">
								<slot :name="name" v-bind="data" />
							</template>
						</message-actions>
					</div>

					<message-reactions
						:current-user-id="currentUserId"
						:message="message"
						@send-message-reaction="sendMessageReaction"
					/>
				</div>
			</slot>
		</div>
	</div>
</template>

<script>
import SvgIcon from '../../components/SvgIcon/SvgIcon'
import FormatMessage from '../../components/FormatMessage/FormatMessage'

import MessageReply from './MessageReply/MessageReply'
import MessageFiles from './MessageFiles/MessageFiles'
import MessageActions from './MessageActions/MessageActions'
import MessageReactions from './MessageReactions/MessageReactions'
import AudioPlayer from './AudioPlayer/AudioPlayer'

const { messagesValidation } = require('../../utils/data-validation')
//const { isAudioFile } = require('../../utils/media-file')

export default {
	name: 'Message',
	components: {
		SvgIcon,
		FormatMessage,
		AudioPlayer,
		MessageReply,
		MessageFiles,
		MessageActions,
		MessageReactions
	},

	props: {
		currentUserId: { type: [String, Number], required: true },
		textMessages: { type: Object, required: true },
		index: { type: Number, required: true },
		message: { type: Object, required: true },
		messages: { type: Array, required: true },
		editedMessage: { type: Object, required: true },
		roomUsers: { type: Array, default: () => [] },
		messageActions: { type: Array, required: true },
		roomFooterRef: { type: HTMLDivElement, default: null },
		newMessages: { type: Array, default: () => [] },
		showReactionEmojis: { type: Boolean, required: true },
		showNewMessagesDivider: { type: Boolean, required: true },
		textFormatting: { type: Boolean, required: true },
		linkOptions: { type: Object, required: true },
		hideOptions: { type: Boolean, required: true }
	},

	emits: [
		'hide-options',
		'message-added',
		'open-file',
		'open-user-tag',
		'message-action-handler',
		'send-message-reaction'
	],

	data() {
		return {
			hoverMessageId: null,
			messageHover: false,
			optionsOpened: false,
			emojiOpened: false,
			newMessage: {},
			progressTime: '- : -',
			hoverAudioProgress: false,
			datePrevious:''

		}
	},

	computed: {
		messageOffset() {
			return (
				this.index > 0 &&
				this.message.senderId !== this.messages[this.index - 1].senderId
			)
		},
		isMessageHover() {
			return (
				this.editedMessage.id_message === this.message.id_message ||
				this.hoverMessageId === this.message.id_message
			)
		},
		isAudio() {
			return this.message.id_message_type==2
		},
		isCheckmarkVisible() {
			return (
				this.message.senderId === this.currentUserId &&
				!this.message.deleted &&
				(this.message.saved || this.message.distributed || this.message.seen)
			)
		}
	},

	watch: {
		newMessages: {
			immediate: true,
			deep: true,
			handler(val) {
				if (!val.length || !this.showNewMessagesDivider) {
					return (this.newMessage = {})
				}

				this.newMessage = val.reduce((res, obj) =>
					obj.index < res.index ? obj : res
				)
			}
		}
	},

	mounted() {
		messagesValidation(this.message)
		this.$emit('message-added', {
			message: this.message,
			index: this.index,
			ref: this.$refs[this.message.id_message]
		})
	},

	methods: {
		showDate() {
			let index = this.messages.findIndex(item=>item.id_message == this.message.id_message)
			if (index==0) {
				return true
			}
			let dt1 = this.dateFormat(this.messages[index-1].data, "DD/MM/YYYY")
			let dt2 = this.dateFormat(this.message.data, "DD/MM/YYYY")
			return (
				dt1 != dt2
			)
		},
		onHoverMessage() {
			this.messageHover = true
			if (this.canEditMessage()) this.hoverMessageId = this.message.id_message
		},
		canEditMessage() {
			return !this.message.deleted
		},
		onLeaveMessage() {
			if (!this.optionsOpened && !this.emojiOpened) this.messageHover = false
			this.hoverMessageId = null
		},
		isDeleted(message){
			return (
				message.deleted && ((message.deleted_to == 'all') || (message.deleted_to.indexOf(this.currentUserId) != -1))
			)
		},
		isNotDeleted(message){
			return (
				!message.deleted || (message.deleted_to.indexOf(this.currentUserId) == -1)
			)
		},
		openFile(file) {
			this.$emit('open-file', { message: this.message, file: file })
		},
		openUserTag(user) {
			this.$emit('open-user-tag', { user })
		},
		messageActionHandler(action) {
			this.messageHover = false
			this.hoverMessageId = null

			setTimeout(() => {
				this.$emit('message-action-handler', { action, message: this.message })
			}, 300)
		},
		sendMessageReaction() {
			this.$emit('send-message-reaction', {
				messageId: this.message.id_message
			})
			this.messageHover = false
		},
		filterChatTag(tag){
			this.$emit('filterChatTag', tag)
		},
	}
}
</script>

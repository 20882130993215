<template>
	<div class="modal-case-form">
		<div class="modal fade" id="caseFormModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="caseFormModalLabel">{{modalCaseFormType == "inserir" ? "Criar novo caso" : "Editar Caso" }}</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="container" id="formulario">
							<h4 class="text-gray mb-4 text-center">Cadastro Pacientes para DMD</h4>
							<form id="form-case" novalidate>
								<div class="bg-secondary p-2" v-if="modalCaseFormType == 'alterar'">
									<h4>Status do Caso</h4>
									<div class="row mt-4">
										<div class="col-12">
											<div class="row d-flex justify-content-center align-items-center">
												<div class="col-8 col-md-3 ps-5 text-center">
													<div class="radio radio-primary" style="">
														<input id="perg_0a" class="shadow-none" type="radio" :value="1" name="perg_0" v-model="form.flg_active">
														<label for="perg_0a" class="text-xs ">Aberto</label>
													</div>

												</div>
												<div class="col-4 col-md-3 text-center">
													<div class="radio radio-primary" style="">
														<input id="perg_0b" class="shadow-none" type="radio" :value="2" name="perg_0" v-model="form.flg_active">
														<label for="perg_0b" class="text-xs">Fechado</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="mt-4 bg-secondary p-2">
									<h4>Identificação do Paciente</h4>
									<div class="row">
										<div class="col-md-12 col-12 mt-md-0">
											<label class="col-form-label input-label">Tag: PTC-"iniciais do paciente", ex: PTC-MJS(Maria José da Silva)</label>
											<div class="input-group">
												<span class="input-group-text" id="prefix">{{farmPrefix}}</span>
												<input type="text" class="form-control shadow-none" id="perg_1" name="perg_1" v-model="form.tag" minlength="3" maxlength="10" required>
											</div>
											<div class="input-error text-danger" v-if="submitStatus && !$v.form.tag.required">
												&nbsp;&nbsp;* Campo Obrigatório
											</div>
											<div class="input-error text-danger" v-if="submitStatus && !$v.form.tag.minLength">
												&nbsp;&nbsp;* Este campo deve conter pelo menos 3 caracteres após "{{farmPrefix}}"
											</div>
										</div>
									</div>
									<div class="row mt-4">
										<div class="col-3">
											<label class="col-form-label input-label">Idade</label>
											<input class="form-control shadow-none" type="text" id="perg_2" name="perg_2" v-model="form.idade" required>
											<div class="input-error text-danger" v-if="submitStatus && !$v.form.idade.required">
												&nbsp;&nbsp;* Campo Obrigatório
											</div>
										</div>
										<div class="col-4 col-md-4">
											<label class="col-form-label input-label">Sexo</label>
											<select class="form-select form-control shadow-none" name="perg_3" id="perg_3" v-model="form.sexo" required>
												<option></option>
												<option value="M">Masculino</option>
												<option value="F">Feminino</option>
											</select>
											<div class="input-error text-danger" v-if="submitStatus && !$v.form.sexo.required">
												&nbsp;&nbsp;* Campo Obrigatório
											</div>
										</div>
										<div class="col-3 col-md-4">
											<label class="col-form-label input-label">Peso</label>
											<input class="form-control shadow-none" type="text" id="perg_4" name="perg_4" v-model="form.peso" required>
											<div class="input-error text-danger" v-if="submitStatus && !$v.form.peso.required">
												&nbsp;&nbsp;* Campo Obrigatório
											</div>
										</div>
									</div>
									<div class="row mt-4">
										<div class="col-12 col-md-8 mb-4">
											<label class="col-form-label input-label">Endereço do Paciente e/ou
												Responsável</label>
											<input class="form-control shadow-none" name="perg_5" type="text" value="" id="perg_5" v-model="form.endereco" required>
											<div class="input-error text-danger" v-if="submitStatus && !$v.form.endereco.required">
												&nbsp;&nbsp;* Campo Obrigatório
											</div>
										</div>
										<div class="col-12 col-md-4 mb-4">
											<label class="col-form-label input-label">Bairro</label>
											<input class="form-control shadow-none" name="perg_6" type="text" value="" id="perg_6" v-model="form.bairro" required>
											<div class="input-error text-danger" v-if="submitStatus && !$v.form.bairro.required">
												&nbsp;&nbsp;* Campo Obrigatório
											</div>
										</div>
										<div class="col-12 col-md-6 mb-4">
											<label class="col-form-label input-label">Cidade</label>
											<input class="form-control shadow-none" name="perg_7" type="text" value="" id="perg_7" v-model="form.cidade" required>
											<div class="input-error text-danger" v-if="submitStatus && !$v.form.cidade.required">
												&nbsp;&nbsp;* Campo Obrigatório
											</div>
										</div>
										<div class="col-12 col-md-2 mb-4">
											<label class="col-form-label input-label">Estado</label>
											<select class="form-select form-control shadow-none text-left" name="perg_8" id="perg_8" v-model="form.estado" required>
												<option></option>
												<option value="AC">AC</option>
												<option value="AL">AL</option>
												<option value="AP">AP</option>
												<option value="AM">AM</option>
												<option value="BA">BA</option>
												<option value="CE">CE</option>
												<option value="DF">DF</option>
												<option value="ES">ES</option>
												<option value="GO">GO</option>
												<option value="MA">MA</option>
												<option value="MT">MT</option>
												<option value="MS">MS</option>
												<option value="MG">MG</option>
												<option value="PA">PA</option>
												<option value="PB">PB</option>
												<option value="PR">PR</option>
												<option value="PE">PE</option>
												<option value="PI">PI</option>
												<option value="RJ">RJ</option>
												<option value="RN">RN</option>
												<option value="RS">RS</option>
												<option value="RO">RO</option>
												<option value="RR">RR</option>
												<option value="SC">SC</option>
												<option value="SP">SP</option>
												<option value="SE">SE</option>
												<option value="TO">TO</option>
												<option value="EX">EX</option>
											</select>
											<div class="input-error text-danger" v-if="submitStatus && !$v.form.estado.required">
												&nbsp;&nbsp;* Campo Obrigatório
											</div>
										</div>
										<div class="col-12 col-md-4">
											<label class="col-form-label input-label">Tel. Paciente e/ou Responsável</label>
											<the-mask :mask="['(##)####-####', '(##)#####-####']" class="form-control masktel shadow-none" name="perg_9" id="perg_9"
												inputmode="text" v-model="form.telefone" required  />
											<div class="input-error text-danger" v-if="submitStatus && !$v.form.telefone.required">
												&nbsp;&nbsp;* Campo Obrigatório
											</div>
											<div class="input-error text-danger" v-if="submitStatus && !$v.form.telefone.minLength">
												&nbsp;&nbsp;* Telefone inválido
											</div>
										</div>
									</div>
								</div>

								<div class="mt-4 bg-secondary p-2">
									<h4>Identificação do Solicitante</h4>
									<div class="row">
										<div class="col-md-12 col-12 mt-md-0">
											<label class="col-form-label input-label">Nome da Clínica ou do Médico Responsável
												do Paciente</label>
											<input class="form-control shadow-none" type="text" name="perg_10" id="perg_10" v-model="form.nome_clinica_medico" required>
											<div class="input-error text-danger" v-if="submitStatus && !$v.form.nome_clinica_medico.required">
												&nbsp;&nbsp;* Campo Obrigatório
											</div>
										</div>
									</div>
									<div class="row mt-4">
										<div class="col-md-12 col-12 mt-md-0">
											<label class="col-form-label input-label">Telefone da Clínica ou do Consultório do
												Médico do Paciente </label>
											<the-mask :mask="['(##)####-####', '(##)#####-####']" class="form-control masktel shadow-none" name="perg_11" id="perg_11"
											inputmode="text" v-model="form.telefone_clinica_medico" required  />
											<div class="input-error text-danger" v-if="submitStatus && !$v.form.telefone_clinica_medico.required">
												&nbsp;&nbsp;* Campo Obrigatório
											</div>
											<div class="input-error text-danger" v-if="submitStatus && !$v.form.telefone_clinica_medico.minLength">
												&nbsp;&nbsp;* Telefone inválido
											</div>
										</div>
									</div>
								</div>

								<div class="mt-4 bg-secondary p-2">
									<h4>Informações Clínicas</h4>
									<div class="row">
										<label class="col-12 pt-2 input-label">Diagnósticos de</label>
										<div class="col-2 col-md-1 mt-2 mb-2 text-end pe-0">
											<div class="checkbox checkbox-primary"
												style="position:relative;left:5px;top:-15px">
												<input id="perg_12" class="shadow-none" type="checkbox" value="1" name="perg_12" v-model="form.atraso_motor"><label
													for="perg_12"></label>
											</div>
										</div>
										<label for="perg_12" class="col-10 col-md-5 text-xs mt-2 mb-2">Atraso do
											desenvolvimento motor</label>

										<div class="col-2 col-md-1 mt-2 mb-2 text-end pe-0">
											<div class="checkbox checkbox-primary"
												style="position:relative;left:5px;top:-15px">
												<input id="perg_13" class="shadow-none" type="checkbox" value="1" name="perg_13" v-model="form.atraso_cognitivo"><label
													for="perg_13"></label>
											</div>
										</div>
										<label for="perg_13" class="col-10 col-md-5 text-xs mt-2 mb-2">Atraso de
											desenvolvimento cognitivo</label>

										<div class="col-2 col-md-1 mt-2 mb-2 text-end pe-0">
											<div class="checkbox checkbox-primary"
												style="position:relative;left:5px;top:-15px">
												<input id="perg_14" class="shadow-none" type="checkbox" value="1" name="perg_14" v-model="form.tgo_tpg_alterada"><label
													for="perg_14"></label>
											</div>
										</div>
										<label for="perg_14" class="col-10 col-md-5 text-xs mt-2 mb-2">TGO e TPG
											alterada</label>


									</div>

									<div class="row mt-4">
										<label class="col-12 pt-2 input-label">Sintomas</label>
										<div class="col-2 col-md-1 mt-2 mb-2 text-end pe-0">
											<div class="checkbox checkbox-primary"
												style="position:relative;left:5px;top:-15px">
												<input id="perg_15" class="shadow-none" type="checkbox" value="1" name="perg_15" v-model="form.fraqueza_muscular"><label
													for="perg_15"></label>
											</div>
										</div>
										<label for="perg_15" class="col-10 col-md-5 text-xs mt-2 mb-2">Fraqueza
											muscular</label>
									</div>

									<div class="row mt-4">
										<label class="col-12 pt-2 input-label">Sinais de Exame Físico</label>
										<div class="col-2 col-md-1 mt-2 mb-2 text-end pe-0">
											<div class="checkbox checkbox-primary"
												style="position:relative;left:5px;top:-15px">
												<input id="perg_16" class="shadow-none" type="checkbox" value="1" name="perg_16" v-model="form.hipertrofia_penturrilha"><label
													for="perg_16"></label>
											</div>
										</div>
										<label for="perg_16" class="col-10 col-md-5 text-xs mt-2 mb-2">Hipertrofia de
											panturrilha</label>

										<div class="col-2 col-md-1 mt-2 mb-2 text-end pe-0">
											<div class="checkbox checkbox-primary"
												style="position:relative;left:5px;top:-15px">
												<input id="perg_17" class="shadow-none" type="checkbox" value="1" name="perg_17" v-model="form.andar_ponta_pes"><label
													for="perg_17"></label>
											</div>
										</div>
										<label for="perg_17" class="col-10 col-md-5 text-xs mt-2 mb-2">Andar na ponta
											dos pés</label>

										<div class="col-2 col-md-1 mt-2 mb-2 text-end pe-0">
											<div class="checkbox checkbox-primary"
												style="position:relative;left:5px;top:-15px">
												<input id="perg_18" class="shadow-none" type="checkbox" value="1" name="perg_18" v-model="form.atraso_andar"><label
													for="perg_18"></label>
											</div>
										</div>
										<label for="perg_18" class="col-10 col-md-5 text-xs mt-2 mb-2">Atraso para
											andar</label>

										<div class="col-2 col-md-1 mt-2 mb-2 text-end pe-0">
											<div class="checkbox checkbox-primary"
												style="position:relative;left:5px;top:-15px">
												<input id="perg_19" class="shadow-none" type="checkbox" value="1" name="perg_19" v-model="form.atraso_falar"><label
													for="perg_19"></label>
											</div>
										</div>
										<label for="perg_19" class="col-10 col-md-5 text-xs mt-2 mb-2">Atraso para
											falar</label>

										<div class="col-2 col-md-1 mt-2 mb-2 text-end pe-0">
											<div class="checkbox checkbox-primary"
												style="position:relative;left:5px;top:-15px">
												<input id="perg_20" class="shadow-none" type="checkbox" value="1" name="perg_20" v-model="form.dificuldade_subir_escadas"><label
													for="perg_20"></label>
											</div>
										</div>
										<label for="perg_20" class="col-10 col-md-5 text-xs mt-2 mb-2">Dificuldade de
											subir escadas</label>

										<div class="col-2 col-md-1 mt-2 mb-2 text-end pe-0">
											<div class="checkbox checkbox-primary"
												style="position:relative;left:5px;top:-15px">
												<input id="perg_21" class="shadow-none" type="checkbox" value="1" name="perg_21" v-model="form.dificuldade_correr"><label
													for="perg_21"></label>
											</div>
										</div>
										<label for="perg_21" class="col-10 col-md-5 text-xs mt-2 mb-2">Dificuldade para
											correr</label>

										<div class="col-2 col-md-1 mt-2 mb-2 text-end pe-0">
											<div class="checkbox checkbox-primary"
												style="position:relative;left:5px;top:-15px">
												<input id="perg_22" class="shadow-none" type="checkbox" value="1" name="perg_22" v-model="form.marcha_anserina"><label
													for="perg_22"></label>
											</div>
										</div>
										<label for="perg_22" class="col-10 col-md-5 text-xs mt-2 mb-2">Marcha
											anserina</label>

										<div class="col-2 col-md-1 mt-2 mb-2 text-end pe-0">
											<div class="checkbox checkbox-primary"
												style="position:relative;left:5px;top:-15px">
												<input id="perg_23" class="shadow-none" type="checkbox" value="1" name="perg_23" v-model="form.sinais_gowers"><label
													for="perg_23"></label>
											</div>
										</div>
										<label for="perg_23" class="col-10 col-md-5 text-xs mt-2 mb-2">Sinais de
											gowers</label>
									</div>

									<div class="row mt-4">
										<label class="col-12 pt-2 input-label">História Patológica Atual e Pregressa</label>

										<div class="col-2 col-md-1 mt-2 mb-2 text-end pe-0">
											<div class="checkbox checkbox-primary"
												style="position:relative;left:5px;top:-15px">
												<input id="perg_24" class="shadow-none" type="checkbox" value="1" name="perg_24" v-model="form.hist_familiar"><label
													for="perg_24"></label>
											</div>
										</div>
										<label for="perg_24" class="col-10 col-md-5 text-xs mt-2 mb-2">História Familiar
											Positiva para doença genética</label>
									</div>

									<div class="row mt-4">
										<label class="col-12 pt-2 input-label">Exames Laboratoriais Prévios em Prontuário
											com</label>
										<div class="col-2 col-md-1 mt-2 mb-2 text-end pe-0">
											<div class="checkbox checkbox-primary"
												style="position:relative;left:5px;top:-15px">
												<input id="perg_25" class="shadow-none" type="checkbox" value="1" name="perg_25" v-model="form.creatinoqunase"><label
													for="perg_25"></label>
											</div>
										</div>
										<label for="perg_25" class="col-10 col-md-5 text-xs mt-2 mb-2">Creatinoquinase
											(CK), ou creatina-fosfoquinase (CPK) aumentada</label>

										<div class="col-2 col-md-1 mt-2 mb-2 text-end pe-0">
											<div class="checkbox checkbox-primary"
												style="position:relative;left:5px;top:-15px">
												<input id="perg_26" class="shadow-none" type="checkbox" value="1" name="perg_26" v-model="form.tgo_ast_aumentada"><label
													for="perg_26"></label>
											</div>
										</div>
										<label for="perg_26" class="col-10 col-md-5 text-xs mt-2 mb-2">TGO/TGP/ALT/AST
											aumentadas</label>

										<div class="col-2 col-md-1 mt-2 mb-2 text-end pe-0">
											<div class="checkbox checkbox-primary"
												style="position:relative;left:5px;top:-15px">
												<input id="perg_27" class="shadow-none" type="checkbox" value="1" name="perg_27" v-model="form.gama_gt_normal"><label
													for="perg_27"></label>
											</div>
										</div>
										<label for="perg_27" class="col-10 col-md-5 text-xs mt-2 mb-2">Gama GT
											normal</label>

									</div>
								</div>

								<div class="mt-4 bg-secondary p-2">
									<h4>Avaliação do Tele-Neuro</h4>
									<div class="row mt-4">
										<div class="col-12">
											<label class="col-form-label input-label">Duchenne possível?</label>
											<div class="row align-items-start ">
												<div class="col-8 col-md-3 ps-5">
													<div class="radio radio-primary" style="">
														<input id="perg_28a" class="shadow-none" type="radio" value="1" name="perg_28" v-model="form.duchenne_possivel">
														<label for="perg_28a" class="text-xs ">SIM</label>
													</div>

													<div class="row" id="telesim">
														<div class="col-10 offset-2">
															<label class="col-form-label input-label">Solicitar CK?</label>
															<div class="row align-items-center ">
																<div class="col-6 col-md-6">
																	<div class="radio radio-primary" style="">
																		<input id="perg_29a" class="shadow-none" type="radio" value="1"
																			name="perg_29" v-model="form.solicitar_ck">
																		<label for="perg_29a"
																			class="text-xs">SIM</label>
																	</div>
																</div>
																<div class="col-6 col-md-6">
																	<div class="radio radio-primary" style="">
																		<input id="perg_29b" class="shadow-none" type="radio" value="0"
																			name="perg_29" v-model="form.solicitar_ck">
																		<label for="perg_29b"
																			class="text-xs">NÃO</label>
																	</div>
																</div>
															</div>
														</div>

														<div class="col-10 offset-2">
															<label class="col-form-label input-label">Solicitar DNAmplo?</label>
															<div class="row align-items-center ">
																<div class="col-6 col-md-6">
																	<div class="radio radio-primary" style="">
																		<input id="perg_30a" class="shadow-none" type="radio" value="1"
																			name="perg_30" v-model="form.solicitar_dna">
																		<label for="perg_30a"
																			class="text-xs ">SIM</label>
																	</div>
																</div>
																<div class="col-6 col-md-6">
																	<div class="radio radio-primary" style="">
																		<input id="perg_30b" class="shadow-none" type="radio" value="0"
																			name="perg_30" v-model="form.solicitar_dna">
																		<label for="perg_30b"
																			class="text-xs">NÃO</label>
																	</div>
																</div>
															</div>
														</div>
													</div>

												</div>
												<div class="col-4 col-md-3">
													<div class="radio radio-primary" style="">
														<input id="perg_28b" class="shadow-none" type="radio" value="0" name="perg_28" v-model="form.duchenne_possivel" required>
														<label for="perg_28b" class="text-xs">NÃO</label>
													</div>
												</div>
											</div>
										</div>
									</div>

								</div>
								<div class="row mt-5">
									<div class="col-12">
										<button type="button"
											class="btn btn-block salvarx w-100 mb-2" @click="submitForm(false)">SALVAR</button>
										<br>
										<button
											type="button" class="btn btn-info text-white btn-block salvarx2 w-100 mb-2"
											@click="submitForm(true)">SALVAR E ENVIAR PARA O CHAT</button>
									</div>
								</div>

							</form>
							<div class="spacer-2x">&nbsp;</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import axios from "axios"
import { API, language_padrao } from '../utils/constants'
import { required, minLength } from 'vuelidate/lib/validators'
export default {
	props:{
		currentFarmaId:{type: String, required:true},
		roomId:{type: String, required:true},
		modalCaseFormType:{type: String, required: true},
		modalCaseFormIdCase:{type: String, required: true},
	},
	data(){
		return {
			modal: null,
			farmPrefix: '',
			submitStatus: false,
			form:{
				tag: "",
				idade: "",
				sexo: "",
				peso: "",
				endereco: "",
				bairro: "",
				cidade: "",
				estado: "",
				telefone: "",
				nome_clinica_medico: "",
				telefone_clinica_medico: "",
				atraso_motor: false,
				atraso_cognitivo: false,
				tgo_tpg_alterada: false,
				fraqueza_muscular: false,
				hipertrofia_penturrilha: false,
				andar_ponta_pes: false,
				atraso_andar: false,
				atraso_falar: false,
				dificuldade_subir_escadas: false,
				dificuldade_correr: false,
				marcha_anserina: false,
				sinais_gowers: false,
				hist_familiar: false,
				creatinoqunase: false,
				tgo_ast_aumentada: false,
				gama_gt_normal: false,
				duchenne_possivel: "",
				solicitar_ck: "",
				solicitar_dna: "",
				flg_active: "",
				token_ficha: "",
				id_ficha_seq: "",
				id_case: ""
			}
		}
	},
	watch: {
		'form.duchenne_possivel':function(newVal){
			if(newVal == '0'){
				this.form.solicitar_ck = ""
				this.form.solicitar_dna = ""
			}
		}
	},
	validations: {
		form: {
			tag: {
				required,
				minLength: minLength(3)
			},
			idade: {
				required
			},
			sexo: {
				required
			},
			peso: {
				required
			},
			endereco: {
				required
			},
			bairro: {
				required
			},
			cidade: {
				required
			},
			estado: {
				required
			},
			telefone: {
				required,
				minLength: minLength(10)
			},
			nome_clinica_medico: {
				required
			},
			telefone_clinica_medico: {
				required,
				minLength: minLength(10)
			}
		}
	},
	mounted(){
		let farmas = this.$parent.$parent.$parent.farmaceuticas
		let currentFarma = farmas.filter(farm=>farm.id_farma == this.currentFarmaId)[0]
		let splitFarma = currentFarma.farma.split('-')[0].replace(/\s+/g, "")
		this.farmPrefix = `${splitFarma}-`

		const modal = document.querySelector("#caseFormModal")
		this.modal = new window.bootstrap.Modal(modal)

		modal.addEventListener("shown.bs.modal", ()=>{
			if(this.modalCaseFormType == "alterar"){
				this.getCase()
			}
		})

		modal.addEventListener("hide.bs.modal", ()=>{
			console.log("hide");
			this.resetForm();
		})
	},
	methods:{
		submitForm(message_send){
			this.$v.form.$touch()
			console.log(message_send);
			if (this.$v.form.$invalid) {
				this.submitStatus = true
				return;
			}else{
				if(this.modalCaseFormType == "inserir"){
					this.enviarMensagemForm(message_send)
				}else{
					this.editCase(message_send)
				}
			}
		},
		async enviarMensagemForm(message_send){
			let self = this
			var {
				tag,
				idade,
				sexo,
				peso,
				endereco,
				bairro,
				cidade,
				estado,
				telefone,
				nome_clinica_medico,
				telefone_clinica_medico,
				atraso_motor,
				atraso_cognitivo,
				tgo_tpg_alterada,
				fraqueza_muscular,
				hipertrofia_penturrilha,
				andar_ponta_pes,
				atraso_andar,
				atraso_falar,
				dificuldade_subir_escadas,
				dificuldade_correr,
				marcha_anserina,
				sinais_gowers,
				hist_familiar,
				creatinoqunase,
				tgo_ast_aumentada,
				gama_gt_normal,
				duchenne_possivel,
				solicitar_ck,
				solicitar_dna
			} = this.form
			atraso_motor = atraso_motor ? '1' : '0'
			atraso_cognitivo = atraso_cognitivo ? '1' : '0'
			tgo_tpg_alterada = tgo_tpg_alterada ? '1' : '0'
			fraqueza_muscular = fraqueza_muscular ? '1' : '0'
			hipertrofia_penturrilha = hipertrofia_penturrilha ? '1' : '0'
			andar_ponta_pes = andar_ponta_pes ? '1' : '0'
			atraso_andar = atraso_andar ? '1' : '0'
			atraso_falar = atraso_falar ? '1' : '0'
			dificuldade_subir_escadas = dificuldade_subir_escadas ? '1' : '0'
			dificuldade_correr = dificuldade_correr ? '1' : '0'
			marcha_anserina = marcha_anserina ? '1' : '0'
			sinais_gowers = sinais_gowers ? '1' : '0'
			hist_familiar = hist_familiar ? '1' : '0'
			creatinoqunase = creatinoqunase ? '1' : '0'
			tgo_ast_aumentada = tgo_ast_aumentada ? '1' : '0'
			gama_gt_normal = gama_gt_normal ? '1' : '0'

			let mensagem = await this.getMessageString()

			var settings = {
				"url": API+"cases/",
				"method": "POST",
				"headers": {
					"X-Auth-Token": localStorage.getItem("api_token"),
					"X-Id-Language": language_padrao,
					"X-Farma": this.currentFarmaId,
					"X-Case": '0',
				},
				data: {
					"flg_deleted":0,
					"id_ficha": "1-56972",
					"tags": self.farmPrefix+tag,
					"flg_active": 1,
					"ficha": {
						'idade': idade,
						'sexo': sexo,
						'peso': peso,
						'endereco': endereco,
						'bairro': bairro,
						'cidade': cidade,
						'estado': estado,
						'telefone': telefone,
						'nome_clinica': nome_clinica_medico,
						'telefone_clinica': telefone_clinica_medico,
						'atraso_motor': atraso_motor,
						'atraso_cognitivo': atraso_cognitivo,
						'tgo_tpg_alterada': tgo_tpg_alterada,
						'fraqueza': fraqueza_muscular,
						'hipertrofia': hipertrofia_penturrilha,
						'andar': andar_ponta_pes,
						'atraso_andar': atraso_andar,
						'atraso_falar': atraso_falar,
						'dificuldade_escadas': dificuldade_subir_escadas,
						'dificuldade_correr': dificuldade_correr,
						'marcha': marcha_anserina,
						'sinais': sinais_gowers,
						'historico_familiar': hist_familiar,
						'creatinoquinase': creatinoqunase,
						'tgo_tpg_aumentada': tgo_ast_aumentada,
						'gama_gt': gama_gt_normal,
						'duchenne': duchenne_possivel,
						'solicitar_ck': solicitar_ck,
						'solicitar_dna': solicitar_dna
					}
				}
			};

			axios(settings).then((response)=>{

				if(message_send){
					let content = {id_case: response.data.list[0].id_case, tag:tag, mensagem:mensagem}
					self.sendMessage(content)
				}else{
					this.$emit('updateCaseList')
					this.$emit('closeCaseModalForm')
				}
			}).catch(function(erro) {
				console.log(erro);
			});
		},
		sendMessage(mensagem){
			this.$emit("sendMessage", mensagem)
			this.$emit('closeCaseModalForm')
		},
		async editCase(message_send){
			let self = this
			var {
				tag,
				idade,
				sexo,
				peso,
				endereco,
				bairro,
				cidade,
				estado,
				telefone,
				nome_clinica_medico,
				telefone_clinica_medico,
				atraso_motor,
				atraso_cognitivo,
				tgo_tpg_alterada,
				fraqueza_muscular,
				hipertrofia_penturrilha,
				andar_ponta_pes,
				atraso_andar,
				atraso_falar,
				dificuldade_subir_escadas,
				dificuldade_correr,
				marcha_anserina,
				sinais_gowers,
				hist_familiar,
				creatinoqunase,
				tgo_ast_aumentada,
				gama_gt_normal,
				duchenne_possivel,
				solicitar_ck,
				solicitar_dna,
				flg_active,
				token_ficha,
				id_ficha_seq,
				id_case
			} = this.form
			atraso_motor = atraso_motor ? '1' : '0'
			atraso_cognitivo = atraso_cognitivo ? '1' : '0'
			tgo_tpg_alterada = tgo_tpg_alterada ? '1' : '0'
			fraqueza_muscular = fraqueza_muscular ? '1' : '0'
			hipertrofia_penturrilha = hipertrofia_penturrilha ? '1' : '0'
			andar_ponta_pes = andar_ponta_pes ? '1' : '0'
			atraso_andar = atraso_andar ? '1' : '0'
			atraso_falar = atraso_falar ? '1' : '0'
			dificuldade_subir_escadas = dificuldade_subir_escadas ? '1' : '0'
			dificuldade_correr = dificuldade_correr ? '1' : '0'
			marcha_anserina = marcha_anserina ? '1' : '0'
			sinais_gowers = sinais_gowers ? '1' : '0'
			hist_familiar = hist_familiar ? '1' : '0'
			creatinoqunase = creatinoqunase ? '1' : '0'
			tgo_ast_aumentada = tgo_ast_aumentada ? '1' : '0'
			gama_gt_normal = gama_gt_normal ? '1' : '0'

			let mensagem = await this.getMessageString()

			var settings = {
				"url": API+"cases/",
				"method": "POST",
				"headers": {
					"X-Auth-Token": localStorage.getItem("api_token"),
					"X-Id-Language": language_padrao,
					"X-Farma": this.currentFarmaId,
					"X-Case": this.modalCaseFormIdCase,
				},
				data: {
					"flg_deleted":0,
					"id_ficha": "1-56972",
					"tags": self.farmPrefix+tag,
					"flg_active": flg_active,
					"ficha": {
						'idade': idade,
						'sexo': sexo,
						'peso': peso,
						'endereco': endereco,
						'bairro': bairro,
						'cidade': cidade,
						'estado': estado,
						'telefone': telefone,
						'nome_clinica': nome_clinica_medico,
						'telefone_clinica': telefone_clinica_medico,
						'atraso_motor': atraso_motor,
						'atraso_cognitivo': atraso_cognitivo,
						'tgo_tpg_alterada': tgo_tpg_alterada,
						'fraqueza': fraqueza_muscular,
						'hipertrofia': hipertrofia_penturrilha,
						'andar': andar_ponta_pes,
						'atraso_andar': atraso_andar,
						'atraso_falar': atraso_falar,
						'dificuldade_escadas': dificuldade_subir_escadas,
						'dificuldade_correr': dificuldade_correr,
						'marcha': marcha_anserina,
						'sinais': sinais_gowers,
						'historico_familiar': hist_familiar,
						'creatinoquinase': creatinoqunase,
						'tgo_tpg_aumentada': tgo_ast_aumentada,
						'gama_gt': gama_gt_normal,
						'duchenne': duchenne_possivel,
						'solicitar_ck': solicitar_ck,
						'solicitar_dna': solicitar_dna,
						'token_ficha': token_ficha,
						'id_ficha_seq': id_ficha_seq,
						'id_case': id_case
					}
				}
			};

			axios(settings).then(()=>{

				if(message_send){
					let content = {id_case: self.modalCaseFormIdCase, tag:tag, mensagem:mensagem}
					self.sendMessage(content)
				}else{
					this.$emit('updateCaseList')
					this.$emit('closeCaseModalForm')
				}
			}).catch(function(erro) {
				console.log(erro);
			});
		},
		getCase(){
			var self = this
            var settings = {
                "url": API+"cases/",
                "method": "GET",
                "headers": {
                "X-Auth-Token": localStorage.getItem("api_token"),
                "X-Id-Language": language_padrao,
				"X-Farma": this.currentFarmaId,
                "X-Case": this.modalCaseFormIdCase,
				"X-CaseStatus": '0'
                },
            };

            axios(settings).then(response=>{
				self.form = {
					tag: response.data.list[0].tags.split('-')[1],
					idade: response.data.list[0].ficha.idade,
					sexo: response.data.list[0].ficha.sexo,
					peso: response.data.list[0].ficha.peso,
					endereco: response.data.list[0].ficha.endereco,
					bairro: response.data.list[0].ficha.bairro,
					cidade: response.data.list[0].ficha.cidade,
					estado: response.data.list[0].ficha.estado,
					telefone: response.data.list[0].ficha.telefone,
					nome_clinica_medico: response.data.list[0].ficha.nome_clinica,
					telefone_clinica_medico: response.data.list[0].ficha.telefone_clinica,
					atraso_motor: (response.data.list[0].ficha.atraso_motor == 1) ? true : false,
					atraso_cognitivo: (response.data.list[0].ficha.atraso_cognitivo == 1) ? true : false,
					tgo_tpg_alterada: (response.data.list[0].ficha.tgo_tpg_alterada == 1) ? true : false,
					fraqueza_muscular: (response.data.list[0].ficha.fraqueza == 1) ? true : false,
					hipertrofia_penturrilha: (response.data.list[0].ficha.hipertrofia == 1) ? true : false,
					andar_ponta_pes: (response.data.list[0].ficha.andar == 1) ? true : false,
					atraso_andar: (response.data.list[0].ficha.atraso_andar == 1) ? true : false,
					atraso_falar: (response.data.list[0].ficha.atraso_falar == 1) ? true : false,
					dificuldade_subir_escadas: (response.data.list[0].ficha.dificuldade_escadas == 1) ? true : false,
					dificuldade_correr: (response.data.list[0].ficha.dificuldade_correr == 1) ? true : false,
					marcha_anserina: (response.data.list[0].ficha.marcha == 1) ? true : false,
					sinais_gowers: (response.data.list[0].ficha.sinais == 1) ? true : false,
					hist_familiar: (response.data.list[0].ficha.historico_familiar == 1) ? true : false,
					creatinoqunase: (response.data.list[0].ficha.creatinoquinase == 1) ? true : false,
					tgo_ast_aumentada: (response.data.list[0].ficha.tgo_tpg_aumentada == 1) ? true : false,
					gama_gt_normal: (response.data.list[0].ficha.gama_gt == 1) ? true : false,
					duchenne_possivel:response.data.list[0].ficha.duchenne.toString(),
					solicitar_ck: response.data.list[0].ficha.solicitar_ck.toString(),
					solicitar_dna: response.data.list[0].ficha.solicitar_dna.toString(),
					flg_active: response.data.list[0].flg_active,
					token_ficha: response.data.list[0].ficha.token_ficha,
					id_ficha_seq: response.data.list[0].ficha.id_ficha_seq,
					id_case: response.data.list[0].ficha.id_case
				}

				//Setar os input para atualizar a mascara
				const inputTelefone = document.getElementById("perg_9");
				inputTelefone.value = response.data.list[0].ficha.telefone;
				inputTelefone.dispatchEvent(new Event('input'));

				const inputTelefone_clinica = document.getElementById("perg_11");
				inputTelefone_clinica.value = response.data.list[0].ficha.telefone_clinica;
				inputTelefone_clinica.dispatchEvent(new Event('input'));

            }).catch(function(erro) {
                console.log(erro);
            });
		},
		async testeMensagem(){
			let mensagem = await this.getMessageString()
			console.log(mensagem);
		},
		getMessageString(){
			return new Promise((resolve) => {
				var {
					idade,
					sexo,
					peso,
					endereco,
					bairro,
					cidade,
					estado,
					telefone,
					nome_clinica_medico,
					telefone_clinica_medico,
					atraso_motor,
					atraso_cognitivo,
					tgo_tpg_alterada,
					fraqueza_muscular,
					hipertrofia_penturrilha,
					andar_ponta_pes,
					atraso_andar,
					atraso_falar,
					dificuldade_subir_escadas,
					dificuldade_correr,
					marcha_anserina,
					sinais_gowers,
					hist_familiar,
					creatinoqunase,
					tgo_ast_aumentada,
					gama_gt_normal,
					duchenne_possivel,
					solicitar_ck,
					solicitar_dna,
				} = this.form
				let mensagem = `\nIdade: ${idade}`
				mensagem = mensagem + `\nSexo: ${sexo}`
				mensagem = mensagem + `\nPeso: ${peso}`
				mensagem = mensagem + `\nEndereço: ${endereco}`
				mensagem = mensagem + `\nBairro: ${bairro}`
				mensagem = mensagem + `\nCidade: ${cidade}`
				mensagem = mensagem + `\nEstado: ${estado}`
				mensagem = mensagem + `\nTelefone: ${telefone}`
				mensagem = mensagem + `\nNome da Clínica ou do Médico Responsável do Paciente: ${nome_clinica_medico}`
				mensagem = mensagem + `\nTelefone da Clínica ou do Consultório do Médico do Paciente: ${telefone_clinica_medico}`
				let checkboxes = [
					{value: "diagnosticos",texto: "Diagnostico de", checkboxes_arr:[
							{isChecked: atraso_motor, value: "Atraso do desenvolvimento motor"},
							{isChecked: atraso_cognitivo, value: "Atraso de desenvolvimento cognitivo"},
							{isChecked: tgo_tpg_alterada, value: "TGO e TPG alterada"}
						]
					},
					{value: "sintomas",texto: "Sintomas", checkboxes_arr:[
							{isChecked: fraqueza_muscular, value: "Fraqueza muscular"}
						]
					},
					{value: "sinais",texto: "Sinais de Exame Físico", checkboxes_arr:[
							{isChecked: hipertrofia_penturrilha, value: "Hipertrofia de panturrilha"},
							{isChecked: andar_ponta_pes, value: "Andar na ponta dos pés"},
							{isChecked: atraso_andar, value: "Atraso para andar"},
							{isChecked: atraso_falar, value: "Atraso para falar"},
							{isChecked: dificuldade_subir_escadas, value: "Dificuldade de subir escadas"},
							{isChecked: dificuldade_correr, value: "Dificuldade para correr"},
							{isChecked: marcha_anserina, value: "Marcha anserina"},
							{isChecked: sinais_gowers, value: "Sinais de gowers"}
						]
					},
					{value: "historia",texto: "História Patológica Atual e Pregressa", checkboxes_arr:[
							{isChecked: hist_familiar, value: "História Familiar Positiva para doença genética"}
						]
					},
					{value: "exames",texto: "Exames Laboratoriais Prévios em Prontuário com", checkboxes_arr:[
							{isChecked: creatinoqunase, value: "Creatinoquinase (CK), ou creatina-fosfoquinase (CPK) aumentada"},
							{isChecked: tgo_ast_aumentada, value: "TGO/TGP/ALT/AST aumentadas"},
							{isChecked: gama_gt_normal, value: "Gama GT normal"}
						]
					}
				]

				//let checkboxes_checked = checkboxes.filter(item=>item.isChecked == '1')
				for(let i=0; i < checkboxes.length; i++){
					let checkboxes_checked = checkboxes[i].checkboxes_arr.filter(item=>item.isChecked == '1')
					if(i==0 && checkboxes_checked.length > 0){
						mensagem = mensagem + `\nInformações Clínicas`
					}
					for (let j = 0; j < checkboxes_checked.length; j++) {
						if(j==0){
							mensagem = mensagem + `\n${checkboxes[i].texto}: ${checkboxes_checked[j].value}`
						}else{
							mensagem = mensagem + `, ${checkboxes_checked[j].value}`
						}
					}
				}

				mensagem = mensagem + `\nDuchenne possível? ${duchenne_possivel == '1' ? 'SIM' : 'NÂO'}`

				if(duchenne_possivel == '1'){
					mensagem = mensagem + `\nSolicitar CK? ${solicitar_ck == '1' ? 'SIM' : 'NÂO'}`
					mensagem = mensagem + `\nSolicitar DNAmplo? ${solicitar_dna == '1' ? 'SIM' : 'NÂO'}`
				}

				resolve(mensagem)
			})
		},
		resetForm(){
			this.submitStatus = false
			this.form = {
				tag: "",
				idade: "",
				sexo: "",
				peso: "",
				endereco: "",
				bairro: "",
				cidade: "",
				estado: "",
				telefone: "",
				nome_clinica_medico: "",
				telefone_clinica_medico: "",
				atraso_motor: "",
				atraso_cognitivo: "",
				tgo_tpg_alterada: "",
				fraqueza_muscular: "",
				hipertrofia_penturrilha: "",
				andar_ponta_pes: "",
				atraso_andar: "",
				atraso_falar: "",
				dificuldade_subir_escadas: "",
				dificuldade_correr: "",
				marcha_anserina: "",
				sinais_gowers: "",
				hist_familiar: "",
				creatinoqunase: "",
				tgo_ast_aumentada: "",
				gama_gt_normal: "",
				duchenne_possivel: "",
				solicitar_ck: "",
				solicitar_dna: ""
			}
		}
	}
}
</script>
<style scoped>
.bg-secondary {
    background-color: var(--chat-modal-case-bg-secondary) !important;
}

.bg-secondary h4 {
    color: var(--chat-modal-case-title-color);
	text-align: center;
}


.input-label {
    font-weight: 500;
    color: var(--chat-modal-case-color);
    font-size: 14px;
}

span#prefix {
    background-color: #fff;
	border: 1px solid #fff;
    padding: 0.375rem 0 0.375rem 0.75rem;
}

#perg_1{
	border: 1px solid #fff;
	padding: 0.375rem 0.75rem 0.375rem 0;
	text-transform: uppercase;
}

select{
	margin-bottom: 0;
}

.checkbox input[type="checkbox"] {
    opacity: 0;
}

.checkbox label {
    display: inline-block;
    position: relative;
    padding-left: 5px;
    margin-bottom: 0;
}

form label {
    color: #111111;
    display: inline-block;
    margin-bottom: 0.5rem;
}

.checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 0;
    margin-top: 2px;
    margin-left: -20px;
    border: 1px solid #b4b8ba;
    border-radius: 3px;
    background-color: white;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.checkbox label::after {
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 0;
    top: 0;
    margin-top: -3px;
    margin-left: -23px;
    padding-left: 4px;
    padding-top: 2px;
    font-size: 10px;
    color: #d4d8da;
}

.checkbox-primary input[type="checkbox"]:checked + label::before {
    background-color: #3f50b5;
    border-color: #3f50b5;
}
.checkbox-primary input[type="checkbox"]:checked + label::after {
    color: white;
}

.checkbox input[type="checkbox"]:checked + label::after {
    font-family: 'Material Icons';
    content: "\2713";
    font-size: 16px;
}

.radio input[type="radio"] {
    opacity: 0;
}

.radio label {
    display: inline-block;
    position: relative;
    padding-left: 5px;
}

.text-xs {
    font-size: 0.75rem!important;
	line-height: 2;
}

.radio label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #d4d8da;
    border-radius: 50%;
    background-color: white;
    margin-top: 2px;
    -webkit-transition: border 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out;
    transition: border 0.15s ease-in-out;
}

.radio-primary input[type="radio"] + label::after {
    background-color: #3f50b5;
}

.radio label::after {
    display: inline-block;
    position: absolute;
    content: " ";
    width: 12px;
    height: 12px;
    left: 3px;
    top: 3px;
    margin-left: -20px;
    border-radius: 50%;
    background-color: #d4d8da;
    margin-top: 2px;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.radio-primary input[type="radio"]:checked + label::after {
    background-color: #3f50b5;
}

.radio input[type="radio"]:checked + label::after {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
}

.radio-primary input[type="radio"] + label::after {
    background-color: #3f50b5;
}

.salvarx{
	background-color: var(--chat-modal-case-button-bg-color);
	color: var(--chat-modal-case-button-color);
}
</style>

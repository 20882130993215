export default {
	ROOMS_EMPTY: 'Nenhuma sala',
	ROOM_EMPTY: 'Sala vazia ',
	NEW_MESSAGES: 'Nova Mensagem',
	MESSAGE_DELETED: 'Esta mensagem foi deletada',
	MESSAGES_EMPTY: 'Nenhuma mensagem',
	CONVERSATION_STARTED: 'Conversa iniciada em:',
	TYPE_MESSAGE: 'Mensagem',
	SEARCH: 'Procurar',
	IS_ONLINE: 'está online',
	LAST_SEEN: 'visto pela última vez ',
	IS_TYPING: 'está escrevendo...'
}

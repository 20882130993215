<template>
	<div class="window-container" :class="{ 'window-mobile': isDevice }">
		<form v-if="addNewRoom" @submit.prevent="createRoom">
			<input v-model="addRoomUsername" type="text" placeholder="Add username" />
			<button type="submit" :disabled="disableForm || !addRoomUsername">
				Create Room
			</button>
			<button class="button-cancel" @click="addNewRoom = false">
				Cancel
			</button>
		</form>

		<form v-if="inviteRoomId" @submit.prevent="addRoomUser">
			<input v-model="invitedUsername" type="text" placeholder="Add username" />
			<button type="submit" :disabled="disableForm || !invitedUsername">
				Add User
			</button>
			<button class="button-cancel" @click="inviteRoomId = null">
				Cancel
			</button>
		</form>

		<form v-if="removeRoomId" @submit.prevent="deleteRoomUser">
			<select v-model="removeUserId">
				<option default value="">
					Select User
				</option>
				<option v-for="user in removeUsers" :key="user._id" :value="user._id">
					{{ user.username }}
				</option>
			</select>
			<button type="submit" :disabled="disableForm || !removeUserId">
				Remove User
			</button>
			<button class="button-cancel" @click="removeRoomId = null">
				Cancel
			</button>
		</form>

		<image-preview :url="url" :show-preview="showPreview" v-if="showPreview" @close-modal-preview="closeModalPreview" />

		<list-dicom :currentFarmaId="currentFarmaId" :roomId="roomId" @sendMessage="sendMessage" @closeDicomModal="closeDicomModal"></list-dicom>
		<list-case :currentFarmaId="currentFarmaId" :roomId="roomId" @filterChatTag="filterChatTag" @sendMessage="sendMessage" @closeCaseModal="closeCaseModal"></list-case>
		<modal-list-participants :currentFarmaId="currentFarmaId" :roomId="roomId" @closeCaseModal="closeCaseModal"/>
		<chat-window
			:loading-messages="loadingMessages"
			:height="screenHeight"
			:theme="theme"
			:styles="styles"
			:current-user-id="currentUserId"
			:ordem="ordem"
			:tagFilter="tagFilter"
			:room-id="roomId"
			:rooms="rooms"
			:loading-rooms="loadingRooms"
			:messages="messages"
			:messages-loaded="messagesLoaded"
			:messages-loaded-bottom="messagesLoadedBottom"
			:rooms-loaded="roomsLoaded"
			:room-actions="roomActions"
			:menu-actions="menuActions"
			:room-message="roomMessage"
			:templates-text="templatesText"
			@filterChatTag="filterChatTag"
			@clearFilterTag="clearFilterTag"
			@openDicomModal="openDicomModal"
			@openCaseModal="openCaseModal"
			@change-order="changeOrder"
			@fetch-more-rooms="fetchMoreRooms"
			@fetch-messages="fetchMessages"
			@send-message="sendMessage"
			@edit-message="editMessage"
			@delete-message-to-me="deleteMessageToMe"
			@delete-message-to-all="deleteMessageToAll"
			@open-file="openFile"
			@open-user-tag="openUserTag"
			@add-room="addRoom"
			@room-action-handler="menuActionHandler"
			@menu-action-handler="menuActionHandler"
			@send-message-reaction="sendMessageReaction"
			@typing-message="typingMessage"
			@toggle-rooms-list="$emit('show-demo-options', $event.opened)"
		>
			<!-- <template #room-header="{ room }">
				{{ room.roomName }}
			</template> -->
		</chat-window>
	</div>
</template>

<script>
import { parseTimestamp, isSameDay } from '../utils/dates'
import axios from 'axios'
import moment from 'moment'
import { API, id_app, language_padrao } from '../utils/constants'
import ChatWindow from '../lib/ChatWindow'
import ImagePreview from '../components/ImagePreview/ImagePreview.vue'
import ListDicom from './ListDicom.vue'
import ListCase from './ListCase.vue'
import ModalListParticipants from './ModalListParticipants.vue'
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
//import { filter } from 'vue/types/umd'
// import ChatWindow, { Rooms } from 'vue-advanced-chat'
// import ChatWindow from 'vue-advanced-chat'
// import 'vue-advanced-chat/dist/vue-advanced-chat.css'
// import ChatWindow from './../../dist/vue-advanced-chat.umd.min.js'

export default {
	components: {
    ChatWindow,
    ImagePreview,
    ListDicom,
    ListCase,
    ModalListParticipants
},

	props: {
		currentUserId: { type: String, required: true },
		currentFarmaId: { type: String, required: true },
		theme: { type: String, required: true },
		isDevice: { type: Boolean, required: true },
		ordem: { type: String, required: true },
		tagFilter: { type: Object, required: true }
	},

	emits: ['show-demo-options'],

	data() {
		return {
			showPreview: false,
			url: '',
			modalDicom: null,
			modalCase: null,
			modalParticipants: null,
			newMessagesCounter:0,
			loadingMessages: (this.isDevice)?false:true,
			isIntervalRoomsInProgress: false,
			isIntervalMessagesInProgress: false,
			counter: 0,
			listenMessagesTimer:null,
			listenRoomsTimer: null,
			currentDateTime: null,
			messagesUsersDeleted: [],
			roomsPerPage: 15,
			rooms: [],
			roomId: '',
			startRooms: null,
			endRooms: null,
			roomsLoaded: false,
			loadingRooms: true,
			allUsers: [],
			loadingLastMessageByRoom: 0,
			roomsLoadedCount: false,
			selectedRoom: null,
			messagesPerPage: 20,
			messages: [],
			messagesLoaded: false,
			messagesLoadedBottom: false,
			roomMessage: '',
			startMessages: null,
			endMessages: null,
			roomsListeners: [],
			listeners: [],
			typingMessageCache: '',
			disableForm: false,
			addNewRoom: null,
			addRoomUsername: '',
			inviteRoomId: null,
			invitedUsername: '',
			removeRoomId: null,
			removeUserId: '',
			removeUsers: [],
			roomActions: [
				{ name: 'inviteUser', title: 'Invite User' },
				{ name: 'removeUser', title: 'Remove User' },
				{ name: 'deleteRoom', title: 'Delete Room' }
			],
			menuActions: [
				{ name: 'getParticipants', title: 'Participantes' }
			],
			styles: { container: { borderRadius: '4px' } },
			templatesText: [
				{
					tag: 'help',
					text: 'This is the help'
				},
				{
					tag: 'action',
					text: 'This is the action'
				},
				{
					tag: 'action 2',
					text: 'This is the second action'
				}
			]
			// ,dbRequestCount: 0
		}
	},

	computed: {
		loadedRooms() {
			return this.rooms.slice(0, this.roomsLoadedCount)
		},
		screenHeight() {
			return this.isDevice ? window.innerHeight + 'px' : 'calc(100vh - 80px)'
		}
	},
	watch:{
		roomId(){
			clearInterval(this.listenMessagesTimer)
			clearInterval(this.listenRoomsTimer)
		}
	},
	mounted() {
		this.fetchRooms()
		this.updateUserOnlineStatus()
		this.$eventBus.$on('toggleDeviceComponent', this.toggleDeviceComponent)
	},
	destroyed(){
		clearInterval(this.listenMessagesTimer)
		clearInterval(this.listenRoomsTimer)
	},
	methods: {
		closeModalPreview(){
			this.showPreview = false
		},
		openDicomModal(){
			const modal = document.querySelector("#dicomListModal")
			this.modalDicom = new window.bootstrap.Modal(modal)
			this.modalDicom.show()
		},
		closeDicomModal(){
			this.modalDicom.hide()
		},
		openCaseModal(){
			const modal = document.querySelector("#caseListModal")
			this.modalCase = new window.bootstrap.Modal(modal)
			this.modalCase.show()
		},
		closeCaseModal(){
			this.modalCase.hide()
		},
		openParticipantsModal(){
			const modal = document.querySelector("#participantsListModal")
			this.modalParticipants = new window.bootstrap.Modal(modal)
			this.modalParticipants.show()
		},
		closeParticipantsModal(){
			this.modalParticipants.hide()
		},
		changeOrder(order){
			clearInterval(this.listenRoomsTimer)
			clearInterval(this.listenMessagesTimer)
			this.$emit('change-order', order)
		},
		toggleDeviceComponent(){
			clearInterval(this.listenMessagesTimer)
			this.getLastRoomsTimer()
		},
		resetRooms() {
			clearInterval(this.listenRoomsTimer)
			this.loadingRooms = true
			this.loadingLastMessageByRoom = 0
			this.roomsLoadedCount = 0
			this.rooms = []
			this.roomsLoaded = true
			this.startRooms = null
			this.endRooms = null
			this.roomsListeners.forEach(listener => listener())
			this.roomsListeners = []
			this.resetMessages()
		},

		resetMessages() {
			clearInterval(this.listenMessagesTimer)
			this.messages = []
			this.messagesLoaded = false
			this.messagesLoadedBottom = false
			this.startMessages = null
			this.endMessages = null
			this.listeners.forEach(listener => listener())
			this.listeners = []
		},

		fetchRooms() {
			this.resetRooms()
			this.fetchMoreRooms()
		},

		async fetchMoreRooms() {
            var self = this
            var settings = {
                "url": API+"rooms/",
                "method": "GET",
                "headers": {
                "X-Auth-Token": localStorage.getItem("api_token"),
                "X-Id-Language": language_padrao,
                "X-Id-APP": id_app,
                "X-Farma": this.currentFarmaId,
                "X-Flg-Deleted": '0'
                },
            };

            axios(settings).then(response=>{
                //console.log(response);
				let rooms = []
				rooms = response.data.list
				rooms = self.orderArray(rooms)
				self.rooms = rooms
				self.roomsLoaded = true
				self.loadingRooms = false
                //this.list=response.data.list
				if(self.isDevice && !self.listenRoomsTimer) self.getLastRoomsTimer()
            }).catch(function(erro) {
                if (erro.response.status == 498) {
					self.reLogin().then(()=>{
						self.fetchMoreRooms()
					})
				}
				if (erro.response.status == 497) {
					clearInterval(this.listenMessagesTimer)
					clearInterval(this.listenRoomsTimer)
					self.logout()
				}
            });
		},
		orderArray(rooms){
			switch (this.ordem) {
				case 'recentes':
					return rooms.sort((a,b)=> moment(b.dt_recent).diff(a.dt_recent) )
				case 'categorias':
					return rooms.sort((a,b)=> (a.grupo.localeCompare(b.grupo) || a.room-b.room ) )
				case 'chats':
					return rooms
				default:
					break;
			}
			//return rooms.sort((a,b)=>new Date(b.dt_recent) - new Date(a.dt_recent) )
		},
		getLastRoomsTimer(){
			var self = this
			self.listenRoomsTimer = setInterval(async () => {
				if (!self.isIntervalRoomsInProgress) {
					await self.getLastRooms()
				}

			}, 5000);
		},
		getLastRooms(){
			var self = this
			this.isIntervalRoomsInProgress = true;
			return new Promise((resolve)=>{
				var settings = {
					"url": API+"rooms/",
					"method": "GET",
					"headers": {
					"X-Auth-Token": localStorage.getItem("api_token"),
					"X-Id-Language": language_padrao,
					"X-Farma":this.currentFarmaId,
					"X-Flg-Deleted": '0',
					},
				};


				axios(settings).then(response=>{
					let rooms = response.data.list
					rooms = self.orderArray(rooms)
					if (JSON.stringify(rooms) != JSON.stringify(self.rooms)) {
						self.rooms = rooms
					}
					this.isIntervalRoomsInProgress = false;
					resolve()
				}).catch(erro=>{
					if (erro.response.status == 498) {
						self.reLogin().then(()=>{
							self.getLastRooms()
						})
					}
					if (erro.response.status == 497) {
						clearInterval(this.listenMessagesTimer)
						clearInterval(this.listenRoomsTimer)
						self.logout()
					}
					console.log(erro);
				})
			})
		},
		listenLastMessage() {
			console.log('listenLastMessage');

		},

		formatLastMessage() {
			console.log('formatLastMessage');
		},

		formatTimestamp(date, timestamp) {
			const timestampFormat = isSameDay(date, new Date(this.currentDateTime)) ? 'HH:mm' : 'DD/MM/YY'
			const result = parseTimestamp(timestamp, timestampFormat)
			return timestampFormat === 'HH:mm' ? `Hoje, ${result}` : result
		},

		async fetchMessages({ room, options = {} }) {
			var self = this
			let id_case = JSON.stringify(this.tagFilter) == "{}" ? "" : this.tagFilter.id_case
			if (options.reset) {
				this.resetMessages()
				this.roomId = room.id_room
				if (this.counter==0) {
					this.counter++
					return
				}
				this.counter = 0
			}
			//console.log('fetchMessages true');
			self.loadingMessages = true
			var settings = {
				"url": API+"messages/",
				"method": "GET",
				"headers": {
					"X-Auth-Token": localStorage.getItem("api_token"),
					"X-Room": room.id_room,
					"X-Id-Language": language_padrao,
					"X-Flg-Deleted": '0',
					"X-ID": options.xId,
					"X-Direction": options.direction,
					"X-ID-Case": id_case
				},
			};
			await axios(settings).then(async response=>{
				let totalMessages = JSON.parse(JSON.stringify(self.messages))
				let messages = response.data.list
				if (options.direction == 'down') {
						//Criar logica para carregar mensagens mais antigas
						if (response.data.list.length == 0) {
							self.messagesLoaded = true
						}
						for (let i = 0; i < messages.length; i++) {
							let reversed = messages.slice().reverse()
							let message = await self.formatMessage(reversed[i])
							totalMessages.unshift(message)
						}
					}
					else{
						if (response.data.list.length == 0) {
							self.messagesLoadedBottom = true
						}
						for (let i = 0; i < messages.length; i++) {
							let message = await self.formatMessage(messages[i])
							totalMessages.push(message)
						}
					}
					self.messages = totalMessages
					//console.log('fetchMessages false');
					self.loadingMessages = false
					if (options.reset) {
						self.getLastMessagesTimer()
					}
			}).catch(function(erro) {
				console.log(erro);
				if (erro.response.status == 498) {
					self.reLogin().then(()=>{
						self.fetchMessages({room, options})
					})
				}
				if (erro.response.status == 497) {
					clearInterval(this.listenMessagesTimer)
					clearInterval(this.listenRoomsTimer)
					self.logout()
				}
				console.log(erro);
			})
		},
		getLastMessagesTimer(){
			var self = this
			self.listenMessagesTimer = setInterval(async () => {
				if (!self.isIntervalMessagesInProgress) {
					let id_message = (self.messages.length > 0) ? self.messages[self.messages.length-1].id_message : 0
					await self.getLastMessages(id_message)
				}
			}, 5000);
		},
		getLastMessages(){
			var self = this
			self.isIntervalMessagesInProgress = true
			self.loadingMessages = true
			let id_case = JSON.stringify(this.tagFilter) == "{}" ? "" : this.tagFilter.id_case
			return new Promise((resolve)=>{
				let id_message = self.messages.length > 0 ? self.messages[self.messages.length-1].id_message : 0
				var settings = {
					"url": API+"messages/",
					"method": "GET",
					"headers": {
					"X-Auth-Token": localStorage.getItem("api_token"),
					"X-Room": this.roomId,
					"X-Id-Language": language_padrao,
					"X-Flg-Deleted": '0',
					"X-ID": id_message,
					"X-Direction": 'up',
					"X-ID-Case": id_case
					},
				};


				axios(settings).then(async response=>{
					let totalMessages = self.messages
					let messages = response.data.list
					if (messages.length > 0) {
						for (let i = 0; i < messages.length; i++) {
							let index = totalMessages.findIndex(item=>item.id_message==messages[i].id_message)
							if (index==-1) {
								let message = await self.formatMessage(messages[i])
								totalMessages.push(message)
							}
						}
						self.messages = totalMessages
					}
					self.loadingMessages = false
					if(!self.isDevice && !self.listenRoomsTimer) self.getLastRoomsTimer()
					setTimeout(() => {
						self.isIntervalMessagesInProgress = false
					}, 500);
					resolve()
				}).catch(erro=>{
					console.log(erro);
					if (erro.response.status == 498) {
						self.reLogin().then(()=>{
							self.getLastMessages()
						})
					}
					if (erro.response.status == 497) {
						clearInterval(this.listenMessagesTimer)
						clearInterval(this.listenRoomsTimer)
						self.logout()
					}
				})
			})
		},
		filterChatTag(tag){
			clearInterval(this.listenRoomsTimer)
			clearInterval(this.listenMessagesTimer)
			this.$emit('filter-tag', tag)
			console.log(tag);
		},
		clearFilterTag(tag){
			this.$emit('clearFilterTag',tag)
		},
		listenMessages() {
			console.log('listenMessages');
		},

		markMessagesSeen() {
			console.log('markMessagesSeen');

		},

		formatMessage(message) {
			//console.log('formatMessage');
			return new Promise((resolve)=>{
				let formatedMessage = message
				formatedMessage.message = decodeURIComponent(message.message)
				if (message.id_message_type == 3) {
					if (Object.keys(message.obs).length){
						formatedMessage.obs.url = decodeURIComponent(message.obs.url)
						formatedMessage.obs.file.name = decodeURIComponent(message.obs.file.name)
						formatedMessage.obs.file.extension = decodeURIComponent(message.obs.file.extension)
						formatedMessage.obs.file.type = decodeURIComponent(message.obs.file.type)
					}
				}
				if (message.id_message_type == 4) {
					if (Object.keys(message.obs).length){
						formatedMessage.obs.urlExam = decodeURIComponent(message.obs.urlExam)
						formatedMessage.obs.url = decodeURIComponent(message.obs.url)
					}
				}
				if (message.reply_msg != '') {
					formatedMessage.reply_msg = decodeURIComponent(message.reply_msg)
				}
				if (Object.keys(message.reply_obs).length) {
					formatedMessage.reply_obs.url = decodeURIComponent(message.reply_obs.url)
					formatedMessage.reply_obs.file.name = decodeURIComponent(message.reply_obs.file.name)
					formatedMessage.reply_obs.file.extension = decodeURIComponent(message.reply_obs.file.extension)
					formatedMessage.reply_obs.file.type = decodeURIComponent(message.reply_obs.file.type)
				}
				resolve(message)
			})
		},

		async sendMessage({ content, roomId, files, replyMessage }, type=1, options={}) {
			console.log("OPAAAA");
			console.log(content, roomId, files, replyMessage,type, options);
			var self = this
			var tipo = type
			var obs = {}
			let fileName = ''

			var id_case = JSON.stringify(this.tagFilter) == "{}" ? "" : this.tagFilter.id_case

			let reply_msg = replyMessage !== null ? replyMessage.id_message : 0
			content = content == '' ? content : encodeURIComponent(content).replaceAll(/'/g, '%27')
			self.newMessagesCounter++
			clearInterval(self.listenRoomsTimer)
			clearInterval(self.listenMessagesTimer)
			self.loadingMessages = true

			if (files != null) {
				tipo = 3
				fileName = self.getFilename()
				var file = this.formattedFiles(fileName,files)
				let urlFile = await self.uploadFile(fileName, files[0], self.roomId)
				let encoded = encodeURIComponent(urlFile).replaceAll(/'/g, '%27')
				//console.log(encoded);
				file[0].url = encoded
				obs = file[0]
			}

			if(tipo == 4){
				obs = options
			}
			console.log(options.message_tag);
			if(options.message_tag){
				obs = options
				id_case = obs.id_case
			}

			var settings = {
				"url": API + "messages/",
				"method": "POST",
				"headers": {
					"Content-Type": "application/json",
					"X-Auth-Token": localStorage.getItem('api_token'),
					"X-Id-Language": language_padrao,
					"X-Room": roomId,

				},
				"data": {
					"tipo": tipo,
					"message": content,
					"obs": obs,
					"id_case": id_case,
					"id_message_reply": reply_msg
				}
			};
			axios(settings).then(()=>{
				self.getLastMessageSended()
				if (self.newMessagesCounter == 1) {
					self.getLastRoomsTimer()
				}
				self.newMessagesCounter--
			}).catch(erro=>{
				if (erro.response.status == 498) {
					self.reLogin().then(()=>{
						self.sendMessage({ content, roomId, files, replyMessage })
					})
				}
				if (erro.response.status == 497) {
					clearInterval(this.listenRoomsTimer)
					self.logout()
				}
				console.log(erro);
			})
		},

		getLastMessageSended(){
			var self = this
			self.loadingMessages = true
			let id_message = self.messages.length > 0 ? self.messages[self.messages.length-1].id_message : 0
			let id_case = JSON.stringify(this.tagFilter) == "{}" ? "" : this.tagFilter.id_case
			var settings = {
				"url": API+"messages/",
				"method": "GET",
				"headers": {
				"X-Auth-Token": localStorage.getItem("api_token"),
				"X-Room": this.roomId,
				"X-Id-Language": language_padrao,
				"X-Flg-Deleted": '0',
				"X-ID": id_message,
				"X-Direction": 'up',
				"X-ID-Case": id_case
				},
			};


			return axios(settings).then(async response=>{
				let totalMessages = self.messages
				let messages = response.data.list
				if (messages.length > 0) {
					for (let i = 0; i < messages.length; i++) {
						let index = totalMessages.findIndex(item=>item.id_message==messages[i].id_message)
						if (index==-1) {
							let message = await self.formatMessage(messages[i])
							totalMessages.push(message)
						}
					}
					self.messages = totalMessages
				}
				self.messages = totalMessages
				self.getLastMessagesTimer()
			}).catch(erro=>{
				if (erro.response.status == 498) {
					self.reLogin().then(()=>{
						self.getLastMessages()
					})
				}
				if (erro.response.status == 497) {
					clearInterval(this.listenMessagesTimer)
					clearInterval(this.listenRoomsTimer)
					self.logout()
				}
				console.log(erro);
			})
		},

		getFilename(){
			var datetime = new Date().toISOString()
			var randomHash = datetime+Math.random().toString(36).slice(-6)
			var randomName = randomHash.replaceAll('-','').replaceAll(':','').replace('T','').replace('Z','').replace('.','')
			return randomName
		},

		async editMessage() {
			console.log('editMessage');
		},

		async deleteMessageToMe({ message, roomId }) {
			console.log('deleteMessageToMe');
			var self = this
			var settings = {
				"url": API+"messages/",
				"method": "DELETE",
				"headers": {
				"X-Auth-Token": localStorage.getItem("api_token"),
				"X-Room": roomId,
				"X-Id-Language": language_padrao,
				"X-ID": message.id_message,
				"X-Type": 'me'
				},
			};
			await axios(settings).then(()=>{
				let msg = self.messages.find(item=>item.id_message==message.id_message)
				msg.message = '-del-'
				msg.id_message_type = 1
				msg.obs = {}
				msg.reply_msg = ''
				msg.reply_obs = {}
			}).catch(function(erro) {
				if (erro.response.status == 498) {
					self.reLogin().then(()=>{
						self.deleteMessageToMe({ message, roomId })
					})
				}
				if (erro.response.status == 497) {
					clearInterval(this.listenMessagesTimer)
					clearInterval(this.listenRoomsTimer)
					self.logout()
				}
			})
		},

		async deleteMessageToAll({ message, roomId }) {
			console.log('deleteMessageToAll');
			var self = this
			var settings = {
				"url": API+"messages/",
				"method": "DELETE",
				"headers": {
				"X-Auth-Token": localStorage.getItem("api_token"),
				"X-Room": roomId,
				"X-Id-Language": language_padrao,
				"X-ID": message.id_message,
				"X-Type": 'all'
				},
			};
			await axios(settings).then(()=>{
				let msg = self.messages.findIndex(item=>item.id_message==message.id_message)
				self.messages.splice(msg,1)
			}).catch(function(erro) {
				if (erro.response.status == 498) {
					self.reLogin().then(()=>{
						self.deleteMessageToAll({ message, roomId })
					})
				}
				if (erro.response.status == 497) {
					clearInterval(this.listenMessagesTimer)
					clearInterval(this.listenRoomsTimer)
					self.logout()
				}
			})

		},

		async uploadFile( fileName, file, roomId ) {
			let type = file.extension || file.type
			if (type === 'svg' || type === 'pdf') {
				type = file.type
			}

			const storage = getStorage();
			const storageRef = ref(storage, `images/${roomId}`);
			const fileRef = ref(storageRef,`${fileName}.${type}`)
			await uploadBytes(fileRef, file.blob)
			let url;
			await getDownloadURL(fileRef).then(snapshot=>{
				url = snapshot
			})
			return url
		},

		formattedFiles(fileName,files) {
			const formattedFiles = []

			files.forEach(file => {
				const messageFile = {
					"file": {name:encodeURIComponent(`${fileName}.${file.extension}`), extension: encodeURIComponent(file.extension), type: encodeURIComponent(file.blob.type), size: file.blob.size},
					"url": file.url || file.localUrl
				}

				if (file.audio) {
					messageFile.audio = true
					messageFile.duration = file.duration
				}

				formattedFiles.push(messageFile)
			})

			return formattedFiles
		},

		openFile({ file }) {
			console.log(file);
			if (file.action == 'download') {
				const xhr = new XMLHttpRequest();
				xhr.responseType = 'blob';
				xhr.onload = function () {
				const blob = xhr.response;
				const link = document.createElement('a');
				link.href = URL.createObjectURL(blob);
				link.download = file.file.file.name;
				link.click();
				URL.revokeObjectURL(link.href);
				};
				xhr.open('GET', file.file.url);
				xhr.send();
			}else{
				this.url = file.file.url
				this.showPreview = true
			}
		},

		async openUserTag() {
			console.log('openUserTag');
		},

		async loadRoom() {
			this.fetchRooms()
		},

		menuActionHandler(action) {
			console.log('menuActionHandler');
			switch (action.action.name) {
				case "getParticipants":
					this.openParticipantsModal()
					break;
			}
		},
		async sendMessageReaction({ messageId, roomId }) {
			var self = this
			//console.log('sendMessageReaction');
			//console.log(reaction, remove, messageId, roomId);
			let msg = self.messages.filter(item=>item.id_message==messageId)
			let valor = msg[0].like > 0 ? "0" : "1"
			var settings = {
				"url": API+"messages/attrib/",
				"method": "POST",
				"headers": {
				"X-Auth-Token": localStorage.getItem("api_token"),
				"X-Room": roomId,
				"X-Id-Language": language_padrao,
				"X-ID": messageId,
				},
				"data": {
					"tipo": 1,
					"valor": valor
				}
			};
			await axios(settings).then(()=>{
				msg[0].like = parseInt(valor)
			}).catch(function(erro) {
				if (erro.response.status == 498) {
					self.reLogin().then(()=>{
						self.deleteMessageToAll({ messageId, roomId })
					})
				}
				if (erro.response.status == 497) {
					clearInterval(this.listenMessagesTimer)
					clearInterval(this.listenRoomsTimer)
					self.logout()
				}
			})
		},

		typingMessage() {
			//console.log('typingMessage');
		},

		async listenRooms() {
			console.log('listenRooms');
		},

		updateUserOnlineStatus() {
			console.log('updateUserOnlineStatus');
		},

		listenUsersOnlineStatus() {
			console.log('listenUsersOnlineStatus');
		},

		addRoom() {
			console.log('addRoom');
		},

		async createRoom() {
			console.log('createRoom');
		},

		inviteUser() {
			console.log('inviteUser');
		},

		async addRoomUser() {
			console.log('addRoomUser');
		},

		removeUser() {
			console.log('removeUser');
		},

		async deleteRoomUser() {
			console.log('deleteRoomUser');
		},

		async deleteRoom() {
			console.log('deleteRoom');
		},

		resetForms() {
			console.log('resetForms');
		}

		// ,incrementDbCounter(type, size) {
		// 	size = size || 1
		// 	this.dbRequestCount += size
		// 	console.log(type, size)
		// }
	}
}
</script>

<style lang="scss" scoped>
.window-container {
	width: 100%;
}

.window-mobile {
	form {
		padding: 0 10px 10px;
	}
}

form {
	padding-bottom: 20px;
}

input {
	padding: 5px;
	width: 140px;
	height: 21px;
	border-radius: 4px;
	border: 1px solid #d2d6da;
	outline: none;
	font-size: 14px;
	vertical-align: middle;

	&::placeholder {
		color: #9ca6af;
	}
}

button {
	background: #1976d2;
	color: #fff;
	outline: none;
	cursor: pointer;
	border-radius: 4px;
	padding: 8px 12px;
	margin-left: 10px;
	border: none;
	font-size: 14px;
	transition: 0.3s;
	vertical-align: middle;

	&:hover {
		opacity: 0.8;
	}

	&:active {
		opacity: 0.6;
	}

	&:disabled {
		cursor: initial;
		background: #c6c9cc;
		opacity: 0.6;
	}
}

.button-cancel {
	color: #a8aeb3;
	background: none;
	margin-left: 5px;
}

.vac-avatar{display: none !important;}

select {
	vertical-align: middle;
	height: 33px;
	width: 152px;
	font-size: 13px;
	margin: 0 !important;
}
</style>
